import React, { useState, useEffect } from "react";
import { FilterSidebar } from "./FilterSidebar";
import { getMyCertificateAPI, getMyCertificateAPIAuthKey } from '../../../apiconfig.js';
// import { NoResultPage } from "./NoResultPage";
import "bootstrap/dist/css/bootstrap.min.css";

import * as translationUtils from "../../../common-utils/translation_utils";
import axios from "axios";
import Pagination from "./Pagination";

export default function Results(props) {
  const [sidepanelfour, setSidePanelFour] = useState(false);
  const [searchText, setSearchText] = useState("hct3");
  const [searchResult, setSearchResult] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState("");
  const [sortByValue, setSortByValue] = useState([""]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostPerPage] = useState(20);
  const [offSet, setOffSet] = useState(0);
  const [prevCheckedItem, setPrevCheckedItem] = useState([]);
  const setCheckedfromChild = (newstate, pageNumber) => {
    console.log("setCheckedfromChild come under result tab calll", newstate);
    setPrevCheckedItem(() => [...newstate]);
  };
  const setClearAll = () => {
    clear();
    let newcheckedItem = [];
    setCheckedfromChild([...newcheckedItem], 1);
  };

  function openNav(e) {
    setSidePanelFour(true);
  }

  const onSearchClick = async () => {
    if (searchInputValue !== "") {
      props.getLoader(true);
      const urlParams = new URLSearchParams(window.location.search);
      let cid = urlParams.getAll('cid');
      let userDetails = localStorage.getItem("userDetails")
      let email = JSON.parse(userDetails).email
      let lang = sessionStorage.getItem("defaultLang")
      let element = document.getElementById(cid)
      let customerId = element.getAttribute("data-legalid")
      let folderList = []
      prevCheckedItem.map((data)=>{
        folderList = [...folderList, ...data.apiPayload]
      })
      await axios({
        method: "POST",
        url: getMyCertificateAPI() +"/search",
        headers: {
          "ocp-apim-subscription-key": getMyCertificateAPIAuthKey(),
        },
        data: {
          CustomerId: customerId,
          SearchBy: searchInputValue,
          UserEmail: email,
          LangCode: lang.toLocaleLowerCase(),
          DocumentsPath: folderList
        },
      })
        .then((response) => {
          console.log("response for search resulttt", response);
          setSearchResult(response?.data || []);
          props.getLoader(false);
        })
        .catch((error) => {
          props.getLoader(false);
        });
    }
  };

  const onDownloadDocs = async (docName, fileUrl) => {
    console.log('fileUrl', fileUrl)
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = docName; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const onPrintDocs = async (fileUrl) => {
    const fileURL = URL.createObjectURL(fileUrl);
    const pdfWindow = window.open();
    pdfWindow.location.href = fileURL;
  };
  function closeNav(e) {
    setSidePanelFour(false);
  }

  const clear = async () => {
    setSearchInputValue("");
    setSearchResult([]);
  };

  const onPressEnter = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      onSearchClick();
    }
  };
  const handleInputValue = (event) => {
    setSearchInputValue(event.target.value);
    if (event.target.value == "") {
      setSearchInputValue("");
    }
  };
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    let pageOffset = (pageNumber - 1) * postsPerPage;
    setOffSet(pageOffset);
  };
  const resultPerPage = (param) => {
    setPostPerPage(param);
    setCurrentPage(1);
    setOffSet(0);
  };
  const changeSortBy = (sortbyvalue) => {
    let pageOffset = (currentPage - 1) * postsPerPage;
    setOffSet(pageOffset);
    setPostPerPage(postsPerPage);
    setSortByValue(sortbyvalue);
  };

  return (
    <div>
      <div>
        <div className="row">
          <div className="col-xl-3 ph-search-sidebar hide-mobile">
            <FilterSidebar
              closeFilterSideBar={closeNav}
              prevCheckedItem={prevCheckedItem}
              setCheckedfromChild={setCheckedfromChild}
              language={props.language}
              setClearAll={setClearAll}
              clearFilter={props.clearFilter}
              setSearchBox={props.setSearchBox}
              setSearchInputValue={setSearchInputValue}
              searchInputValue={searchInputValue}
              handleInputValue={handleInputValue}
              onPressEnter={onPressEnter}
              onSearchClick={onSearchClick}
            />
          </div>
          <div className="col-md-12 col-lg-12 col-xl-9 results">
            {sidepanelfour && (
              <div className="overlay-filter-open">
              <div
                id="mySidepanel1"
                className="sidepanel filter-open-box mobile-filter-box-open"
              >
                <div className=" top-filter-bar">
                  <div className="filters_search filter-text">Filters</div>
                  <div>
                    <a
                      href="javascript:void(0)"
                      className="closebtn "
                      onClick={closeNav}
                    >
                      ×
                    </a>
                  </div>
                  <span className="break-line">
                    <hr />
                  </span>
                </div>

                <FilterSidebar
                  closeFilterSideBar={closeNav}
                  prevCheckedItem={prevCheckedItem}
                  setCheckedfromChild={setCheckedfromChild}
                  language={props.language}
                  setClearAll={setClearAll}
                  clearFilter={props.clearFilter}
                  setSearchBox={props.setSearchBox}
                  setSearchInputValue={setSearchInputValue}
                  searchInputValue={searchInputValue}
                  handleInputValue={handleInputValue}
                  onPressEnter={onPressEnter}
                  onSearchClick={onSearchClick}
                />
              </div>
              </div>
            )}
            <div className="mob-all-filter all-filter-mobile">
              <hr />
              <div className="card-header search-box" id="">
                <div class="srch-input">
                  <input
                    type="text"
                    id="txt_search_within"
                    placeholder={"Search for your documents here..."}
                    value={searchInputValue}
                    onChange={handleInputValue}
                    onKeyDown={(event) => onPressEnter(event)}
                  />
                </div>

                <div className="srch-btn">
                  <button class="btn" type="button" onClick={onSearchClick}>
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
              <button
                class="btn btn-import-user mob-open-filter-btn"
                id="mypanelSupport"
                onClick={(e) => openNav(e)}
              >
                <span>All Filters</span>
              </button>
              <hr />
            </div>

            <div className="srp-area">
              <div className="result-cert-header">
                <div className="filter-row">
                  <span>Filters:</span>
                  {/* <div className="selected-filters"> */}
                  {prevCheckedItem.map((data) => {
                    return <button className="button round-button outline-button my-1 py-1 filter-selected-buttons">
                    {data.name} <i className="fa fa-close"></i>
                  </button>
                  })}
                  <button
                    className="button round-button outline-button my-1 py-1"
                    onClick={() => setClearAll()}
                  >
                    {translationUtils.getTranslation(912, "Clear All")}{" "}
                    <i className="fa fa-close"></i>
                  </button>
                  {/* </div> */}
                </div>
                <hr />
              </div>
              <div className="srp-header">
                <div className="showing-top-results">
                  <h4 className="matCert-result-heading">
                    Document Saecrh Result
                  </h4>
                </div>
                {/* <div className="sortby">
                  <h4>{translationUtils.getTranslation(85, "Sort by")}:</h4>

                  <select
                    className="form-select form-select-sm"
                    onChange={(e) => changeSortBy(e.target.value)}
                  >
                    <option value="">Creation Date</option>
                    <option value="title_s asc">Modification Date</option>
                    <option value="title_s desc">Z-A</option>

                    <option value="publishedCiDate_dt desc">
                      {translationUtils.getTranslation(3721, "Newest")}-
                      {translationUtils.getTranslation(3726, "Oldest")}
                    </option>
                    <option value="publishedCiDate_dt asc">
                      {translationUtils.getTranslation(3726, "Oldest")}-
                      {translationUtils.getTranslation(3721, "Newest")}
                    </option>
                  </select>
                </div> */}
              </div>
              <div className="row">
                <div className="main-body-gen">
                  <div className="section-1 qualitydocWrapper">
                    <div className="line marginLine"></div>
                    {searchResult.length > 0 ? (
                      <div className="qualityDocresultContainer">
                        {searchResult.map((data) => {
                          return (
                            <div>
                              <div className="row qualityDocResultTable">
                                <div className="row hct-title">
                                  Heat Code Certificate: {data.FileName}
                                </div>
                                {/* <div className="col-lg-2 col-md-2 col-sm-2 hct-checkbox">
                                  <input type="checkbox"></input>
                                </div> */}
                                <div className="col-lg-2 col-md-2 col-sm-2 img-col">
                                  <div className="dummmy-img"></div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 content-col">
                                  <div>
                                    <span className="qualityDocTablekey">
                                      Document Store Name:{" "}
                                    </span>
                                    <span className="qualityDocTableValue">
                                      {data.Division}
                                    </span>
                                  </div>
                                  <div>
                                    <span className="qualityDocTablekey">
                                      Creation Date:{" "}
                                    </span>
                                    <span className="qualityDocTableValue">
                                      {data.CreationDate}
                                    </span>
                                  </div>
                                  <div>
                                    <span className="qualityDocTablekey">
                                      Modification Date:{" "}
                                    </span>
                                    <span className="qualityDocTableValue">
                                      {data.ModifiedDate}
                                    </span>
                                  </div>
                                </div>
                                <div className="col-lg-4 col-md-12 col-sm-12 action-col">
                                  <div className="button_column">
                                    <button
                                      type="submit"
                                      className="btn btn-import-user download-btn"
                                      onClick={() =>
                                        onDownloadDocs(
                                          data.FileName,
                                          data.DownloadUrl
                                        )
                                      }
                                    >
                                      Download
                                    </button>
                                  </div>
                                  {/* <div className="button_column">
                                    <button
                                      type="submit"
                                      className="btn btn-import-user print-btn"
                                      onClick={() =>
                                        onPrintDocs(data.DownloadUrl)
                                      }
                                    >
                                      Print
                                    </button>
                                  </div> */}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <div className="col-md-12 col-lg-12 col-xl-12 no-result-section">
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* */}
          </div>
        </div>
      </div>
      {/* {searchResult.length > 0 && (
        <div>
          <Pagination
            resultInfo={searchResult}
            currentPage={currentPage}
            totalPosts={searchResult.length}
            postsPerPage={postsPerPage}
            paginate={paginate}
            resultperPage={resultPerPage}
          />
        </div>
      )} */}
    </div>
  );
}
