import React from 'react';
import * as translationUtils from '../../../common-utils/translation_utils';

const ApiKeyPagination = ({ totalPages, currentPage, handlePageChange }) => {
  const renderPaginationItems = () => {
    const items = [];
    const maxVisiblePages = 5;

    // Previous button
    if (currentPage > 1) {
      items.push(
        <li key="prev" className="text-center page-item">
          <a className="page-link" onClick={() => handlePageChange(currentPage - 1)}>{translationUtils.getTranslation(716, 'Previous')}</a>
        </li>
      );
    }

    // Logic for displaying pagination numbers
    if (totalPages <= maxVisiblePages) {
      // If total pages are less than or equal to maxVisiblePages, show all pages
      for (let i = 1; i <= totalPages; i++) {
        items.push(
          <li key={i} className={`text-center page-item ${currentPage === i ? 'active' : ''}`}>
            <a className="page-link" onClick={() => handlePageChange(i)}>{i}</a>
          </li>
        );
      }
    } else {
      // Show the first page
      items.push(
        <li key={1} className={`text-center page-item ${currentPage === 1 ? 'active' : ''}`}>
          <a className="page-link" onClick={() => handlePageChange(1)}>1</a>
        </li>
      );

      // Show ellipsis if currentPage is greater than 3
      if (currentPage > 3) {
        items.push(<li key="ellipsis-start" className="text-center page-item apiKey-ellipsis"><span className="page-link">...</span></li>);
      }

      // Calculate start and end page numbers
      const startPage = Math.max(2, currentPage - 1);
      const endPage = Math.min(totalPages - 1, currentPage + 1);

      // Show the current page and its neighbors
      for (let i = startPage; i <= endPage; i++) {
        items.push(
          <li key={i} className={`text-center page-item ${currentPage === i ? 'active' : ''}`}>
            <a className="page-link" onClick={() => handlePageChange(i)}>{i}</a>
          </li>
        );
      }

      // Show ellipsis if currentPage is less than totalPages - 2
      if (currentPage < totalPages - 2) {
        items.push(<li key="ellipsis-end" className="text-center page-item apiKey-ellipsis"><span className="page-link">...</span></li>);
      }

      // Show the last page
      items.push(
        <li key={totalPages} className={`text-center page-item ${currentPage === totalPages ? 'active' : ''}`}>
          <a className="page-link" onClick={() => handlePageChange(totalPages)}>{totalPages}</a>
        </li>
      );
    }

    // Next button
    if (currentPage < totalPages) {
      items.push(
        <li key="next" className="text-center page-item">
          <a className="page-link" onClick={() => handlePageChange(currentPage + 1)}>{translationUtils.getTranslation(717, 'Next')}</a>
        </li>
      );
    }

    return items;
  };

  return (
    <ul className="pagination">
      {renderPaginationItems()}
    </ul>
  );
};

export default ApiKeyPagination;