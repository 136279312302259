import React, { Component } from 'react'
import './assets/styles/custom-styles.css';
import Result from './components/Results';
import PageTitle  from './components/PageTitle';

export default class MyCertificates extends Component {

    constructor(props) {
        super(props);
    }

    render() {
            return (
                <div className="search-app">
                    <PageTitle />
                    <div className="ph-search-wrapper container-fluid">     
                    <Result langCode={this.props.lang} getLoader={this.props.getLoader}/>
                    </div>
              </div>
                )

            }
}