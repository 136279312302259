import axios from 'axios'
import * as apis from '../common-utils/api_urls'
import * as translationUtils from '../common-utils/translation_utils'
import { getAPIAuthKey } from '../apiconfig';


/**
 * Get Token Data Service
 * @param {MainWrapper} callingClass 
 * @param {string} apiUrl 
 * @param {string} authToken 
 */
export default async function getApiKeyList( uerId) {
    return new Promise((resolve, reject) => {

        const headers = {
            "Ocp-Apim-Subscription-Key": getAPIAuthKey(),
            "Cache-Control": "no-cache",
            "Accept": 'application/json',
            'Access-Control-Allow-Origin': '*'
        };
        
        let url = `${apis.getApiKeyAPIURL('list')}?userEmailAddress=${JSON.parse(localStorage.getItem('okta-token-storage'))?.idToken?.claims?.email}`;
        axios(url, {
            method: 'GET',
            headers: headers,
        }).then((result) => {
            if (result.data) {
                result.data.sort((a, b) => {
                    const c = new Date(a.TokenCreationDateTime);
                    const d = new Date(b.TokenCreationDateTime);
                    return d - c;
                });
                resolve(result.data);
                // callingClass.props.getLoader(false);
            }
        }).catch((error) => {
            console.log(error)
            resolve([]);
        });
    });
}

export async function getSubKeyList(callingClass) {
    return new Promise((resolve, reject) => {
    const headers = {
        "Ocp-Apim-Subscription-Key": getAPIAuthKey(),
        "Cache-Control": "no-cache",
        "Accept": 'application/json'
    };
     fetch(apis.getApiKeyAPIURL('getSubKey'))
    .then(response => response.json())
    .then(data =>  {
        if (data) {
            resolve(data);
        }
    })
    .catch(error => {
        console.error('Error:', error)
        resolve([]);
    });
    });
}
export async function createApiKey(data) {
    const headers = {
        "Ocp-Apim-Subscription-Key": getAPIAuthKey(),
        "Cache-Control": "no-cache",
        "Accept": 'application/json'
    };

    let response = await axios(apis.getApiKeyAPIURL('create'), {
        method: 'POST',
        headers: headers,
        data: {
            'apiKeyName':data?.apiKeyName,
            'emailAddress': data?.apiKeyEmail,
            'createdBy': JSON.parse(localStorage.getItem('okta-token-storage'))?.idToken?.claims?.email,
            'apiKeyProductMapping': data?.apiKeySubscription
        }
    }).then(
        function (result) {
            return result?.data;
        }
    ).catch(
        function (error) {
            console.log(error)
            return error.message;
            // callingClass.props.setStatusMessage('Failed','Token is not created.')
            // callingClass.props.getLoader(false);
        }
    )
    return response;
}

export async function updateApiKey(data) {
    const headers = {
        "Ocp-Apim-Subscription-Key": getAPIAuthKey(),
        "Cache-Control": "no-cache",
        "Accept": 'application/json'
    };

    let response = await axios(apis.getApiKeyAPIURL('update'), {
        method: 'POST',
        headers: headers,
        data: data
    }).then(
        function (result) {
            return result?.data;
        }
    ).catch(
        function (error) {
            console.log(error)
            return error.message;
            // callingClass.props.setStatusMessage('Failed','Token is not created.')
            // callingClass.props.getLoader(false);
        }
    )
    return response;
}

export async function deactivateApiKey(apiKeyId) {
    const headers = {
        "Ocp-Apim-Subscription-Key": getAPIAuthKey(),
        "Cache-Control": "no-cache",
        "Accept": 'application/json'
    };

    try {
        const response = await axios.delete(`${apis.getApiKeyAPIURL('deactivate')}/${apiKeyId}`);
        if (response.data) {
          return response.data;
        }
      } catch (error) {
        console.error('Error:', error);
        return null;
      }
    
}
