import React, { Component } from 'react';
import getDeligatedAuthUserData, {
  getListOfLocation,
} from '../../services/getDeletegatedAdminData';
import $, { nodeName } from 'jquery';
import editUser from './editUser';
import { Link } from 'react-router-dom';

import moment from 'moment';
import MultiSelectList from './MultiSelectList';
import {
  getLocationByLogonId,
  getResendEmailSetting,
} from '../../common-utils/api_urls';
import * as translationUtils from '../../common-utils/translation_utils';
import ImportUser from './ImportUser';
import { ResetMaill } from '../../services/getUserDetail';
import ResendEmailPopup from './ResendEmailPopup';
import { getExternalHost } from '../../apiconfig';
import TagManager from 'react-gtm-module';

let listOfOrg = [];
class AuthUser extends Component {
  $ = window.jQuery;
  constructor(props) {
    super(props);
    this.state = {
      orgList: this.props.headerData.orgList,
      allUsers: [],
      locationData: [],
      isLoading: false,
      jQuery: window.jQuery,
      roles: [],
      oneTime: true,
      customerRolesToShow: 0,
      customerRolesMaxShow: 5,
      showMore: true,
      collapsedIndex: -1,
      locIdLoad: null,
      sorting: 'DESC',
      userSearchText: '',
      resetUsers: null,
      activeTab: 'all',
      open: true,
      status: '',
      message: '',
    };
    this.getLocation = this.getLocation.bind(this);
    this.userRolesToShow = this.userRolesToShow.bind(this);
    //this.filterSearchData = this.filterSearchData.bind(this);
    this.setDeligatedAuthUserData = this.setDeligatedAuthUserData.bind(this);
    //console.log('orgList==', this.state.orgList);
    //console.log('locId==', this.state.locId);
  }

  componentDidMount() {
    // let bussinesslocation =
    //   cid == 'all' ? 'All Locations' : currentLocation[0]?.orgName;
    // let locationId = cid == 'all' ? 'all' : currentLocation[0]?.legalId;

    var previousURL = getCookie('previousURL');
    function getCookie(name) {
      var cookies = document.cookie.split('; ');

      for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i].split('=');

        if (cookie[0] === name) {
          return cookie[1];
        }
      }
      return 'N/A';
    }
    if (decodeURIComponent(previousURL) == window.location.href) {
      previousURL = getCookie('previousURL1');
    }
    document.cookie =
      'previousURL=' +
      encodeURIComponent(window.location.href) +
      '; domain=parker.com; path=/';

    TagManager.dataLayer({
      dataLayer: {
        event: 'page_view',
        page_name: 'Myparker Delegated Admin Homepage',
        page_referrer: decodeURIComponent(previousURL),
        content_group: 'myparker: Admin',
        country: 'N/A',
        language: sessionStorage.getItem('defaultLang')
          ? sessionStorage.getItem('defaultLang')
          : 'EN',
        content_owner: '687PDC',
        nav_owner: '687PDC',
        product_category_name: 'N/A',
        product_series_name: 'N/A',
        product_series_id: 'N/A',
        product_id: 'N/A',
        part_name: 'N/A',
        part_number: 'N/A',
        is_category_page: 'No',
        is_series_page: 'No',
        is_pdp: 'No',
        is_req_quote: 'N/A',
        is_wtb: 'N/A',
        is_buyable_online: 'N/A',
        is_configurable: 'N/A',
        user_ip: window.sessionStorage.getItem('ClientIPAddress'),
        user_id: window.sessionStorage.getItem('WCTrustedToken')
          ? window.sessionStorage.getItem('WCTrustedToken').split('%')[0]
          : 'N/A',
        user_authenticated: 'authenticated user - MyParker',

        user_company_name: window.sessionStorage.getItem('orgListWithName')
          ? window.sessionStorage.getItem('orgListWithName')
          : 'N/A',
        user_company_id: window.sessionStorage.getItem('orgListWithName')
          ? window.sessionStorage
              .getItem('orgListWithName')
              .split('(')[1]
              .replace(/[()]/g, '')
          : 'N/A',

        // user_company_id: window.sessionStorage.getItem('orgListWithId')
        //   ? window.sessionStorage.getItem('orgListWithId').replace(/[()]/g, '')
        //   : '',
      },
    });

    // window.$("#my-select").multipleSelect({
    //     filter: false,
    //     buttonWidth: '348px'
    // });

    window.loader(true);
    let arr = [];

    // this.state.orgList.map((item) =>
    //   item.isAdmin ? arr.push(item.orgId) : []
    // );
    // this.state.orgList.map((item) => {
    //   if (item.isAdmin == false && item.cid == this.props.cid) {
    //     window.location.href =
    //       getExternalHost() +
    //       'portal/site/MyParker/menuitem.37eb03f20c5d959b1f7c238a4256d1ca/?vgnextoid=59519942ad8bf510VgnVCM10000025651dacRCRD&cid=' +
    //       this.props.cid +
    //       '&vgnextfmt=' +
    //       this.props.lang;
    //     // return window.location.href;
    //   }
    // });
    $('.dataTables_length').change(function () {
      window.jQuery('#show_dt_text').text('tatti 2');
    });
  }

  componentWillUpdate() {
    if (this.state.isLoading === true) {
      // console.log(this.state.isLoading)
      this.setState({ isLoading: false });
    }
  }
  setUserProperties(userDetails) {
    //console.log("userDetails",userDetails);
    this.props.userProperties(userDetails);

    localStorage.setItem('userDetails', JSON.stringify(userDetails));
  }

  setAPIResponose = (status, message) => {
    if (status == 200) {
      status = 'Success';
    } else {
      status = 'failed';
    }
    this.setState({
      status: status,
      message: message,
      open: true,
    });
    console.log('PasswordStatus', this.state.status);
    console.log('PasswordMess', message);
  };
  setResendEmail(e, userDetails) {
    //e.preventDefault();
    //e.stopPropagation();
    //console.log('userDetails', userDetails);
    ResetMaill(this, getResendEmailSetting(userDetails.email));
  }

  assignModal = () => {
    this.setState({ open: false });
  };

  setLocationList(locationListData) {
    this.props.locationList(locationListData);
  }
  userRolesToShow() {
    this.setState({
      customerRolesToShow: this.state.customerRolesToShow + 5,
      customerRolesMaxShow: this.state.customerRolesMaxShow + 5,
      showMore: false,
    });
  }
  // setUserIPAddress(data) {
  //   console.log('MyIP===', data);
  //   this.setState({ isSetIP: data });
  // }
  setDeligatedAuthUserData(data) {
    //window.loader(true);

    this.setState(
      {
        allUsers: data,
        allUser2: data,
        resetUsers: data,
        sortedUsers: data.userList,
      },

      () => window.paginate()
    );
    // window.loader(false);
    //if($('#header').find('.sticky-wrapper').length>0){this.getLoader(false)}
  }

  getLocation(logonId, index) {
    this.setState({ isLoading: true });
    if (this.state.collapsedIndex != index) {
      this.setState({
        collapsedIndex: index,
        customerRolesMaxShow: 5,
        showMore: true,
      });
    } else {
      this.setState({
        collapsedIndex: -1,
        customerRolesMaxShow: 5,
        showMore: true,
      });
    }
    let apiUrl = getLocationByLogonId(logonId, this.state.locId);

    if (this.state.locIdLoad != undefined) {
      getListOfLocation(this, apiUrl, this.state.locIdLoad, logonId);
    } else {
      let arr = [];
      this.state.orgList.map((item) =>
        item.isAdmin ? arr.push(item.orgId) : []
      );
      getListOfLocation(this, apiUrl, arr.toString(), logonId);
    }
  }
  closeTab() {
    this.setState({
      collapsedIndex: -1,
      customerRolesMaxShow: 5,
      showMore: false,
    });
  }

  componentDidUpdate() {
    if (
      this.state.allUser2 &&
      this.state.allUser2.userList.length > 0 &&
      this.state.oneTime
    ) {
      this.state.oneTime = false;
    }
  }

  getRoles(data) {
    this.setState({
      roles: data,
    });
  }
  getLocationCallBack(data) {
    this.setState({
      locationData: data,
      sortedLocationData: data.rolesForCustomers,
    });
  }
  getLoader(flag) {
    this.props.getLoader(flag);
  }
  onUserLocChange = (locId, orgListWithName) => {
    //console.log('Inside Location Method');
    window.localStorage.setItem('locId', locId);
    // window.loader(true);
    window.scrollTo(0, 0);
    this.getLoader(true);

    listOfOrg = orgListWithName;
    //setCookie('orgListWithName', orgListWithName);
    console.log('listOfOrg==', listOfOrg[0]);

    this.props.setSelectedLocations(orgListWithName);

    //console.log("Location1", listOfOrg);
    //console.log('locId', locId);
    let arr = [];
    window.destroyTable();
    getDeligatedAuthUserData(this, locId);
    if ($('#header').find('.sticky-wrapper').length > 0) {
      setTimeout(() => {
        if (this.state.allUser2 != null) {
          if (
            $('.searchTerm')[0] != undefined &&
            $('.searchTerm')[0].value != undefined &&
            $('.searchTerm')[0].value != ''
          ) {
            this.filterData(this.state.activeTab);
          }

          this.getLoader(false);
        }
      }, 5000);
    } /*else {
      setTimeout(() => {
        this.filterData(this.state.activeTab)
      this.getLoader(false)
      }, 5000)
    }*/

    //this.props.getLoader(false)
    // }

    this.setState({
      locIdLoad: locId,
      collapsedIndex: -1,
    });
    //window.loader(false);
    //let companyName = listOfOrg[0].label.split('(')[0];
    //let companyId = listOfOrg[0].label.split('(')[1];
    if (listOfOrg && listOfOrg.length > 0) {
      window.sessionStorage.setItem('orgListWithName', listOfOrg[0].label);
    }
    //window.sessionStorage.setItem('orgListWithId', companyId);
  };

  filterSearchData(flag) {
    this.filterData('all');
    console.log('users', this.state.allUsers);
    this.props.getLoader(true);
    const userData = this.state.allUsers ? this.state.allUsers.userList : '';
    let wordText = flag ? '' : $('.searchTerm')[0].value;

    let filtData = userData.filter(function (value) {
      return (
        (value.firstName !== null &&
          value.firstName.toLowerCase().includes(wordText.toLowerCase())) ||
        (value.email !== null &&
          value.email.toLowerCase().includes(wordText.toLowerCase())) ||
        (value.lastName !== null &&
          value.lastName.toLowerCase().includes(wordText.toLowerCase()))
      );
    });
    window.destroyTable();

    this.setState(
      (prevState) => {
        let allUsers = Object.assign({}, prevState.allUsers);
        allUsers.userList = filtData;
        //console.log(allUsers);
        return { allUsers };
      },
      () => window.paginate()
    );

    setTimeout(() => {
      this.props.getLoader(false);
    }, 3000);
  }
  filterData = (type) => {
    this.props.getLoader(true);
    const userData = this.state.allUser2.userList;
    let filtData;
    let wordText = this.props.userSearchText;

    if (type === 'key') {
      /* filtData = userData.filter(function (value) {
        return (
          (value.firstName !== null &&
            value.firstName.includes(e.target.value)) ||
          (value.email !== null && value.email.includes(e.target.value)) ||
          (value.lastName !== null && value.lastName.includes(e.target.value))
        );
      }); */
    } else if (type === 'all') {
      filtData = userData;
      /*filtData = userData.filter(function (value) {
        return !value.isDeactivated && !value.isPending;
      });*/
    } else if (type === 'active') {
      filtData = userData.filter(function (value) {
        return !value.isDeactivated && !value.isPending;
      });
    } else if (type === 'pending') {
      filtData = userData.filter(function (value) {
        return value.isPending;
      });
    } else if (type === 'deactive') {
      filtData = userData.filter(function (value) {
        return value.isDeactivated;
      });
    }

    if (wordText != '') {
      filtData = filtData.filter(function (value) {
        return (
          (value.firstName !== null &&
            value.firstName.toLowerCase().includes(wordText.toLowerCase())) ||
          (value.email !== null &&
            value.email.toLowerCase().includes(wordText.toLowerCase())) ||
          (value.lastName !== null &&
            value.lastName.toLowerCase().includes(wordText.toLowerCase()))
        );
      });
    }

    window.destroyTable();
    this.setState({ activeTab: type });
    this.setState(
      (prevState) => {
        let allUsers = Object.assign({}, prevState.allUsers);
        allUsers.userList = filtData;
        //console.log(allUsers);
        return { allUsers };
      },
      () => window.paginate()
    );
    setTimeout(() => {
      this.props.getLoader(false);
    }, 3000);
    // window.paginate()
  };
  collapsedInnerTable(e) {
    //console.log(e);
    // this.setState({ collapsedIndex: e.target.id });
  }
  locationSorting(type) {
    let sortedLocations = [];
    sortedLocations = this.state.locationData.rolesForCustomers.slice(
      0,
      this.state.customerRolesMaxShow
    );
    if (this.state.sorting == 'DESC' || this.state.sorting == '') {
      sortedLocations.sort((a, b) => b[type].localeCompare(a[type]));
    }
    if (this.state.sorting == 'ASC') {
      sortedLocations.sort((a, b) => a[type].localeCompare(b[type]));
    }

    this.state.locationData.rolesForCustomers
      .slice(
        this.state.customerRolesMaxShow,
        this.state.locationData.rolesForCustomers.length
      )
      .map((val, index) => sortedLocations.push(val));

    this.setState({
      sortedLocationData: sortedLocations,
      sorting: this.state.sorting == 'DESC' ? 'ASC' : 'DESC',
    });
  }

  userSorting(type) {
    let sortedUsers = [];
    sortedUsers = this.state.allUsers.userList;
    if (type == 'orgCount') {
      if (this.state.sorting == 'DESC' || this.state.sorting == '') {
        sortedUsers.sort((a, b) => b[type] - a[type]);
      }
      if (this.state.sorting == 'ASC') {
        sortedUsers.sort((a, b) => a[type] - b[type]);
      }
    } else if (type == 'lastSession') {
      if (this.state.sorting == 'DESC' || this.state.sorting == '') {
        sortedUsers.sort(
          (a, b) => new Date(b[type]).getTime() - new Date(a[type]).getTime()
        );
      } else {
        sortedUsers.sort(
          (a, b) => new Date(a[type]).getTime() - new Date(b[type]).getTime()
        );
      }
    } else {
      if (this.state.sorting == 'DESC' || this.state.sorting == '') {
        sortedUsers.sort((a, b) =>
          b[type].trim().localeCompare(a[type].trim())
        );
      }
      if (this.state.sorting == 'ASC') {
        sortedUsers.sort((a, b) =>
          a[type].trim().localeCompare(b[type].trim())
        );
      }
    }
    console.log('sortedUsers', sortedUsers);
    this.setState({
      sortedUsers: sortedUsers,
      sorting: this.state.sorting == 'DESC' ? 'ASC' : 'DESC',
    });
    window.destroyTable();
    this.setState(
      (prevState) => {
        let allUsers = Object.assign({}, prevState.allUsers);
        allUsers.userList = sortedUsers;
        //console.log("hrr",allUsers);
        return { allUsers };
      },
      () => window.paginate()
    );
  }

  handleChange(e) {
    //this.setState({userSearchText: e.target.value})
    this.props.setuserSearchText(e.target.value);
  }
  handleKeyPress(e) {
    console.log('KeyPress', this.props.userSearchText);
    if (this.props.userSearchText != '' && e.charCode == 13) {
      this.filterData(this.state.activeTab);
    }
  }
  resetUserSearch() {
    this.props.setuserSearchText('');
    this.filterSearchData(true);
    window.location.href = window.location.href;
  }

  render() {
    function showResultTrans() {
      window
        .jQuery('#show_dt_text')
        .text(translationUtils.getTranslation(621, 'Results Per Page'));
      window
        .jQuery('#js-ph-datatable_previous')
        .text(translationUtils.getTranslation(716, 'Previous'));
      window
        .jQuery('#js-ph-datatable_next')
        .text(translationUtils.getTranslation(717, 'Next'));
      window
        .jQuery('#js-ph-datatable_next')
        .text(translationUtils.getTranslation(717, 'Next'));
      var showingEng = window.jQuery('.dataTables_info').text().split(' ');

      window
        .jQuery('.dataTables_info')
        .text(
          translationUtils.getTranslation(1305, 'Showing') +
            ' ' +
            showingEng[1] +
            ' ' +
            translationUtils.getTranslation(180, 'to') +
            ' ' +
            showingEng[3] +
            ' ' +
            translationUtils.getTranslation(51, 'of') +
            ' ' +
            showingEng[5] +
            ' ' +
            translationUtils.getTranslation(1849, 'entries')
        );
    }
    showResultTrans();
    $('.dataTables_length').change(function () {
      showResultTrans();
    });

    $('.dataTables_paginate').click(function () {
      showResultTrans();
    });
    return (
      <div>
        {/* <div id="preloader" style={{ height: '100%' }} >
          <div className="loading-overlay" >&nbsp;</div>
        </div> */}
        <div className="container-fluid">
          <div className="ph-header-main__title row px-md-4 px-lg-3">
            <div className="col">
              <h1 className="p-title">
                {translationUtils.getTranslation(2167, 'Authorized Users')}{' '}
              </h1>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="ph-header-main__breadcrumbs row px-md-4 px-lg-3 py-2 ">
            <div className="col">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb">
                  <li className="breadcrumb-item" aria-current="page">
                    <a href={$('.navbar-brand').attr('href')}>
                      {translationUtils.getTranslation(791, 'Home')}
                    </a>
                  </li>
                  <li className="breadcrumb-item" aria-current="page">
                    {translationUtils.getTranslation(2167, 'Authorized Users')}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>

        <div className="container px-5">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="content-section">
                <div className="d-flex flex-row justify-content-end pr-3">
                  <div>
                    <button
                      type="button"
                      className="mr-auto button accent-button round-button medium-button cround"
                      data-toggle="modal"
                      data-target="#importuserModal"
                    >
                      {translationUtils.getTranslation(2168, 'Import Users')}
                    </button>
                  </div>
                  <ImportUser
                    orgList={this.state.orgList}
                    userInfo={this.props.userInfo}
                  />
                  <div>
                    <Link
                      to={
                        '/' +
                        this.props.lang +
                        '/myparker/addUser?cid=' +
                        this.props.cid
                      }
                    >
                      {' '}
                      <button
                        type="button"
                        onClick={() => this.setLocationList(this.state.orgList)}
                        className="button primary-button round-button medium-button ml-1 cround"
                      >
                        {translationUtils.getTranslation(1088, 'Add') +
                          ' ' +
                          translationUtils.getTranslation(2185, 'User')}
                      </button>
                    </Link>
                  </div>
                  <div>
                    <Link
                      to={
                        '/' +
                        this.props.lang +
                        '/myparker/tokens?cid=' +
                        this.props.cid
                      }
                    >
                      {' '}
                      <button
                        type="button"
                        className="button primary-button round-button medium-button ml-1 cround"
                      >
                        {translationUtils.getTranslation(3356, 'Manage Tokens')}
                      </button>
                    </Link>
                  </div>
                  <div>
                    <Link
                      to={
                        '/' +
                        this.props.lang +
                        '/myparker/apiKey?cid=' +
                        this.props.cid
                      }
                    >
                      {' '}
                      <button
                        type="button"
                        className="button primary-button round-button medium-button ml-1 cround"
                      >
                        {/* {translationUtils.getTranslation(3356, 'Manage API Keys')} */}
                        Manage API Keys
                      </button>
                    </Link>
                  </div>
                </div>
                <nav>
                  <div
                    className="nav nav-tabs ph-tab-group-underlined"
                    id="users-tabs"
                    role="tablist"
                  >
                    <a
                      className="nav-item nav-link ph-tab-group-underlined__item active"
                      data-toggle="tab"
                      href="#users-all"
                      onClick={(e) => this.filterData('all')}
                      role="tab"
                      aria-controls="view-all-users"
                      aria-selected="true"
                    >
                      <div>
                        {translationUtils.getTranslation(2171, 'All Users')}

                        <span>
                          {this.state.allUsers.recordSetCount
                            ? '(' + this.state.allUsers.recordSetCount + ')'
                            : '(0)'}
                        </span>
                      </div>
                    </a>
                    <a
                      href
                      className="nav-item nav-link ph-tab-group-underlined__item"
                      data-toggle="tab"
                      onClick={(e) => this.filterData('active')}
                      role="tab"
                      aria-controls="view-active"
                      aria-selected="false"
                    >
                      <div>
                        {translationUtils.getTranslation(2169, 'Active')}

                        <span>
                          {this.state.allUsers.activeUserCount
                            ? '(' + this.state.allUsers.activeUserCount + ')'
                            : '(0)'}
                        </span>
                      </div>
                    </a>
                    <a
                      href
                      className="nav-item nav-link ph-tab-group-underlined__item"
                      data-toggle="tab"
                      onClick={(e) => this.filterData('pending')}
                      role="tab"
                      aria-controls="view-pending"
                      aria-selected="false"
                    >
                      <div>
                        {translationUtils.getTranslation(2170, 'Pending')}
                        <span>
                          {this.state.allUsers.pendingUserCount
                            ? '(' + this.state.allUsers.pendingUserCount + ')'
                            : '(0)'}
                        </span>
                      </div>
                    </a>
                    <a
                      className="nav-item nav-link ph-tab-group-underlined__item"
                      data-toggle="tab"
                      href="#users-pending"
                      onClick={(e) => this.filterData('deactive')}
                      role="tab"
                      aria-controls="view-pending"
                      aria-selected="false"
                    >
                      <div>
                        {translationUtils.getTranslation(2069, 'Inactive')}
                        <span>
                          {this.state.allUsers.deactiveUserCount
                            ? '(' + this.state.allUsers.deactiveUserCount + ')'
                            : '(0)'}
                        </span>
                      </div>
                    </a>
                  </div>
                </nav>
                <hr />
              </div>
              <div className="row">
                <div className="col-lg-5">
                  <label className="input-label">
                    {translationUtils.getTranslation(
                      2172,
                      'Filter by User Location'
                    )}
                  </label>
                  <div className="dropdown-div">
                    {
                      <MultiSelectList
                        listOfOrg={this.props.selectedLocations}
                        options={this.state.orgList}
                        cid={this.props.cid}
                        onUserLocChange={this.onUserLocChange}
                      />
                    }
                  </div>
                </div>
                <div className="col-lg-6">
                  <label htmlFor="search" className="input-label">
                    {translationUtils.getTranslation(617, 'Keyword Search')}
                  </label>
                  <div className="search mr-3">
                    <input
                      type="text"
                      className="searchTerm"
                      style={{ fontFamily: 'Roboto, FontAwesome' }}
                      placeholder={
                        '\uF002 ' +
                        translationUtils.getTranslation(
                          2173,
                          'Quick filter by name and email'
                        )
                      }
                      onChange={(e) => this.handleChange(e)}
                      onKeyPress={(e) => this.handleKeyPress(e)}
                      value={this.props.userSearchText}
                    />
                    <a
                      href
                      className="searchButton"
                      onClick={() => this.filterData(this.state.activeTab)}
                    >
                      {translationUtils.getTranslation(46, 'Search')}
                    </a>
                  </div>
                </div>
                <div className="col-lg-1 reset-search-key">
                  <a
                    href
                    className="keyword-reset"
                    onClick={() => this.resetUserSearch()}
                  >
                    <span>
                      {' '}
                      {translationUtils.getTranslation(58, 'Reset')}{' '}
                    </span>
                  </a>
                </div>
              </div>
              <div className="text-font py-3">
                {translationUtils.getTranslation(2171, 'All Users')}
              </div>

              <table id="js-ph-datatable" style={{ width: '100%' }}>
                <thead>
                  <tr>
                    <th>
                      <div className="ph-flex-table__header d-flex flex-column flex-sm-row justify-content-around">
                        <div
                          className="bd-highlight ph-flex-table__item"
                          style={{ flex: '0.2' }}
                        >
                          &nbsp;
                        </div>
                        <div
                          className="bd-highlight ph-flex-table__item"
                          onClick={() => this.userSorting('email')}
                        >
                          <p>
                            {translationUtils.getTranslation(355, 'Name')}
                            <i className="fa fa-sort th-margin"></i>
                          </p>
                        </div>
                        <div
                          className="bd-highlight ph-flex-table__item"
                          onClick={() => this.userSorting('email')}
                        >
                          <p>
                            {translationUtils.getTranslation(408, 'Email')}{' '}
                            <i className="fa fa-sort th-margin"></i>
                          </p>{' '}
                        </div>
                        <div className="bd-highlight ph-flex-table__item">
                          <p>
                            {translationUtils.getTranslation(
                              2174,
                              'Last Login'
                            )}{' '}
                            ({translationUtils.getTranslation(
                              3720,
                              'UTC time'
                            )})
                          </p>
                        </div>
                        <div className="bd-highlight ph-flex-table__item">
                          <p>
                            {translationUtils.getTranslation(67, 'Locations')}
                          </p>
                        </div>
                        <div className="bd-highlight ph-flex-table__item">
                          <p>
                            {' '}
                            {translationUtils.getTranslation(1842, 'Status')}
                          </p>
                        </div>
                        <div className="bd-highlight ph-flex-table__item">
                          &nbsp;
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.allUsers.userList &&
                  this.state.allUsers.userList.length > 0
                    ? this.state.allUsers.userList.map((user, index) => (
                        <tr key={index}>
                          <td>
                            <div className="ph-browse-list-view__product">
                              <div
                                className={
                                  this.state.collapsedIndex == index
                                    ? 'ph-browse-list-view__content'
                                    : 'ph-browse-list-view__content collapsed'
                                }
                                // data-toggle="collapse"
                                // data-target={`#collapseDetail${index}`}
                                // aria-expanded={
                                //   this.state.collapsedIndex == index
                                // }
                                // aria-controls={`collapseDetail${index}`}
                              >
                                <div className="ph-flex-table__row d-flex flex-column flex-sm-row justify-content-around">
                                  <div
                                    className="bd-highlight ph-flex-table__item pt-1 extra_cursor"
                                    style={{ flex: '0.2' }}
                                    onClick={() =>
                                      this.getLocation(user.logonId, index)
                                    }
                                  >
                                    <i
                                      className={
                                        this.state.collapsedIndex == index
                                          ? 'fa fa-chevron-down'
                                          : 'fa fa-chevron-right'
                                      }
                                      aria-hidden="true"
                                    ></i>
                                  </div>
                                  <div className="bd-highlight ph-flex-table__item pt-1">
                                    <div>
                                      {user.firstName +
                                        ' ' +
                                        user.lastName +
                                        ' '}
                                    </div>

                                    <i
                                      className={
                                        user.isAdmin
                                          ? 'fa fa-user img-style'
                                          : 'fa img-style'
                                      }
                                    ></i>
                                  </div>
                                  <div className="bd-highlight ph-flex-table__item pt-1">
                                    <a className="anchor-email">
                                      {user.email ? user.email : 'N/A'}
                                    </a>
                                  </div>
                                  <div className="bd-highlight ph-flex-table__item pt-1">
                                    {user.lastSession
                                      ? moment(user.lastSession).format(
                                          'MM/DD/YYYY hh:mm A'
                                        )
                                      : 'N/A'}
                                  </div>
                                  <div className="bd-highlight ph-flex-table__item pt-1">
                                    {/* <a className="updownlink anchor-locations"> */}
                                    {user.orgCount}
                                    {/* </a> */}
                                  </div>
                                  <div className="bd-highlight ph-flex-table__item pt-1">
                                    <span
                                      className={
                                        user.isDeactivated
                                          ? 'ph-flag ph-flag__status--deactivated'
                                          : user.isPending
                                          ? 'ph-flag ph-flag__status--pending'
                                          : 'ph-flag ph-flag__status--active'
                                      }
                                    >
                                      {user.isDeactivated
                                        ? 'INACTIVE'
                                        : user.isPending
                                        ? translationUtils.getTranslation(
                                            2170,
                                            'Pending'
                                          )
                                        : translationUtils.getTranslation(
                                            2169,
                                            'Active'
                                          )}
                                    </span>
                                  </div>
                                  <div className="bd-highlight ph-flex-table__item pt-1">
                                    {user.isPending ? (
                                      <p className="ph-flex-table__item--icon-end">
                                        <i
                                          className="fa fa1 fa-envelope"
                                          style={{ cursor: 'pointer' }}
                                          onClick={(e) =>
                                            this.setResendEmail(e, user)
                                          }
                                          title={translationUtils.getTranslation(
                                            2222,
                                            'Resend Invite'
                                          )}
                                        ></i>{' '}
                                      </p>
                                    ) : (
                                      <p className="ph-flex-table__item--icon-end">
                                        <Link
                                          to={
                                            '/' +
                                            this.props.lang +
                                            '/myparker/editUser?cid=' +
                                            this.props.cid
                                          }
                                        >
                                          <i
                                            className="fa fa1 fa-pencil"
                                            onClick={() =>
                                              this.setUserProperties(user)
                                            }
                                            title={
                                              translationUtils.getTranslation(
                                                1167,
                                                'Edit'
                                              ) +
                                              ' ' +
                                              translationUtils.getTranslation(
                                                2185,
                                                'User'
                                              )
                                            }
                                          ></i>{' '}
                                        </Link>
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>

                              <div
                                className={
                                  this.state.collapsedIndex == index
                                    ? 'ph-browse-list-view__details pt-2 px-2 collapse show in'
                                    : 'ph-browse-list-view__details pt-2 px-2 collapse'
                                }
                                id={`collapseDetail${index}`}
                              >
                                <div className="location-style">
                                  {' '}
                                  {translationUtils.getTranslation(
                                    67,
                                    'Locations'
                                  )}
                                  :
                                </div>
                                {this.state.isLoading === true ? (
                                  <div id="row-loading">
                                    <img
                                      alt=""
                                      src={
                                        process.env.PUBLIC_URL +
                                        '/assets/img/row-loader.gif'
                                      }
                                      style={{ height: '25px' }}
                                    />{' '}
                                    <span className="load_ing">
                                      {translationUtils.getTranslation(
                                        521,
                                        'Loading ...'
                                      )}
                                    </span>
                                  </div>
                                ) : (
                                  <div className="contain-table">
                                    <table className="ph-table" width="100%">
                                      <thead className="ph-table__header">
                                        <tr className="ph-table__row wrap-text">
                                          <th
                                            className="ph-table__title noVis"
                                            scope="col"
                                            onClick={() => {
                                              this.locationSorting(
                                                'customerID'
                                              );
                                            }}
                                          >
                                            {translationUtils.getTranslation(
                                              2175,
                                              'Location Number'
                                            )}
                                            <i className="fa fa-sort th-margin"></i>
                                          </th>
                                          <th
                                            className="ph-table__title"
                                            scope="col"
                                            onClick={() => {
                                              this.locationSorting(
                                                'customerName'
                                              );
                                            }}
                                          >
                                            {translationUtils.getTranslation(
                                              2176,
                                              'Location Name'
                                            )}
                                            <i className="fa fa-sort th-margin"></i>
                                          </th>
                                          <th
                                            className="ph-table__title"
                                            scope="col"
                                          >
                                            {translationUtils.getTranslation(
                                              2177,
                                              'Roles'
                                            )}
                                            <i className="fa fa-sort th-margin"></i>
                                          </th>
                                        </tr>
                                      </thead>
                                      {this.state.sortedLocationData &&
                                      this.state.sortedLocationData.length >
                                        0 ? (
                                        this.state.sortedLocationData
                                          .slice(
                                            0,
                                            this.state.customerRolesMaxShow
                                          )
                                          .map((role, idx) => (
                                            <>
                                              <tbody className="ph-table__body">
                                                <tr
                                                  className="ph-table__row wrap-text"
                                                  id="js-clickable-row"
                                                >
                                                  <td className=" pt-2 pb-2">
                                                    {role.customerID}
                                                  </td>
                                                  <td className=" pt-2 pb-2">
                                                    {role.customerName}
                                                  </td>
                                                  <td className=" pt-2 pb-2">
                                                    {role.roles.includes('-21')
                                                      ? translationUtils.getTranslation(
                                                          2223,
                                                          'Administrator'
                                                        )
                                                      : role.roles.includes(
                                                          '-31'
                                                        )
                                                      ? translationUtils.getTranslation(
                                                          2185,
                                                          'User'
                                                        )
                                                      : role.roles.includes(
                                                          '-21'
                                                        ) &&
                                                        role.roles.includes(
                                                          '-31'
                                                        )
                                                      ? translationUtils.getTranslation(
                                                          2223,
                                                          'Administrator'
                                                        ) +
                                                        '' +
                                                        translationUtils.getTranslation(
                                                          2185,
                                                          'User'
                                                        )
                                                      : translationUtils.getTranslation(
                                                          2069,
                                                          'Inactive'
                                                        )}
                                                  </td>
                                                </tr>
                                              </tbody>
                                            </>
                                          ))
                                      ) : (
                                        <>
                                          <tbody>
                                            <tr>
                                              <td
                                                valign="top"
                                                colSpan="3"
                                                className="dataTables_empty"
                                              >
                                                {translationUtils.getTranslation(
                                                  2090,
                                                  'No Records Found'
                                                )}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </>
                                      )}
                                    </table>
                                  </div>
                                )}
                              </div>

                              {this.state.sortedLocationData != null &&
                                this.state.sortedLocationData.length > 5 &&
                                this.state.collapsedIndex == index && (
                                  <div
                                    className="show-more-bar"
                                    onClick={this.userRolesToShow}
                                  >
                                    <div className="pb-0">
                                      {this.state.sortedLocationData.length >
                                      this.state.customerRolesMaxShow
                                        ? translationUtils.getTranslation(
                                            1305,
                                            'Showing'
                                          ) +
                                          this.state.customerRolesMaxShow +
                                          ' ' +
                                          translationUtils.getTranslation(
                                            51,
                                            'of'
                                          )
                                        : translationUtils.getTranslation(
                                            1305,
                                            'Showing'
                                          ) +
                                          ' ' +
                                          this.state.sortedLocationData.length +
                                          ' ' +
                                          translationUtils.getTranslation(
                                            51,
                                            'of'
                                          )}
                                      {this.state.sortedLocationData.length}{' '}
                                      {translationUtils.getTranslation(
                                        67,
                                        'Locations'
                                      )}
                                    </div>
                                    {this.state.sortedLocationData.length >
                                      this.state.customerRolesMaxShow && (
                                      <div
                                        className="pb-0 default"
                                        style={{
                                          cursor: 'pointer',
                                          fontWeight: 'bold',
                                        }}
                                      >
                                        {translationUtils.getTranslation(
                                          1978,
                                          'Show More'
                                        )}
                                      </div>
                                    )}
                                    <div
                                      className="pb-0"
                                      onClick={() => this.closeTab(index)}
                                    >
                                      <a
                                        href="#"
                                        data-toggle="collapse"
                                        data-target={`#collapseDetail${index}`}
                                        aria-expanded="false"
                                        aria-controls={`collapseDetail${index}`}
                                      >
                                        <i className="icon fas fa-times"></i>
                                        {translationUtils.getTranslation(
                                          676,
                                          'Close'
                                        )}
                                      </a>
                                    </div>
                                  </div>
                                )}
                            </div>
                          </td>
                        </tr>
                      ))
                    : ''}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {
          /* Popup used for Sending Email in Auth User Section */
          this.state.open && this.state.status == 'Success' && (
            <ResendEmailPopup
              status={this.state.status}
              message={this.state.message}
              open={this.state.open}
              assignModal={this.assignModal}
            />
          )
        }
      </div>
    );
  }
}
export default AuthUser;
