import React, { Component } from 'react'
import './assets/styles/custom-styles.css';
import './assets/styles/media-styles.css';
import './assets/styles/sidebar.css';
import ResultTypeTabs from './components/ResultTypeTabs';
import PageTitle  from './components/PageTitle';
import Pagination from './components/Pagination';
// import "https://cdn.tailwindcss.com";

export default class MyParkerSearch extends Component {

    constructor(props) {
        super(props);
        console.log("orgLists",props.orgList)
    }

    render() {
            return (
                <div className="search-app">
              
                    <PageTitle />
                    <div className="ph-search-wrapper container-fluid">     
                    <ResultTypeTabs langCode={this.props.lang} 
                                orgList={this.props.orgList}
                    />
                     {/* <Pagination />  */}
                    </div>
              </div>
                )

            }
}