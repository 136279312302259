import React, {useState, useEffect} from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Results from './Results';
import Pagination from './Pagination';
import { useHistory } from "react-router-dom";
import * as translationUtils from '../../../common-utils/translation_utils';
import { getPid, getTabs2 } from '../utils/FacetKeys';
import { getHostForDA, getAPIAuthKey, getPhEnvDetail } from '../../../apiconfig.js';
import TagManager from 'react-gtm-module';

export default function ResultTypeTabs (props){

  const urlParams = new URLSearchParams(window.location.search);
  const [activeKey, setActiveKey] = useState(urlParams.getAll('selectCategory').length > 0 ? urlParams.getAll('selectCategory') : "support" );
  const [results, setResults] = useState([]);
  const [numCount, setNumCount] = useState(null);
  const [searchBox, setSearchBox] = useState(urlParams.getAll('searchbox'))
   const [authId, setAuthId] = useState(`1-9R05F|1-9R05G|1-9R05J|1-9R05L|1-9R06A`);

   const [sortByValue, setSortByValue] = useState(['']);
  const [currentPage, setCurrentPage] = useState(1);
  let limit = urlParams.getAll('limit');
       if(limit.length == 0){
          limit = 20;
       } else {
          limit = Number(limit);
       }
   const [postsPerPage, setPostPerPage] = useState(limit);
   const [offSet, setOffSet] = useState(0);
   const history = useHistory();
  const currentUrl = new URL(window.location.href);
   const [allFacets, setAllFacets] = useState([]);
   const [allFacetsAPIData, setAllFacetsAPIData] = useState([]);
   let pid;
   let fq_tag;
   const language = props.langCode;
   
   let cid = urlParams.getAll('cid');
  const [custmerId, setCustomerId] = useState('000914');

  const [prevCheckedItem, setPrevCheckedItem] = useState([]);
  const [tabSwitchState, setTabSwitchState] = useState(false);
  const [catalogState, setCatalogState] = useState(urlParams.getAll("catalog") == "general" ? 0 : 1);
  const [supportpid, setSupportpid] = useState("");
  const [productpid, setProductpid] = useState("");
  const [xrefpid, setXrefpid] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showNoResult, setShoNoResult] = useState(null);

   let response1;

   let searchDLToggle = true;

   const setCheckedfromChild = (newstate, pageNumber) => {
    console.log('setCheckedfromChild come under result tab calll', newstate)
    setPrevCheckedItem(() => [...newstate])
    if(pageNumber) setCurrentPage(pageNumber); setOffSet(0);
   }
    // Change page
    const paginate = (pageNumber) => {
      setIsLoading(true)
       setCurrentPage(pageNumber);
       console.log("getpid", getPid(supportpid))
      let pageOffset =  (pageNumber - 1) * limit;
      setOffSet(pageOffset)
    }

     const resultPerPage = (param) =>{
      setIsLoading(true)
      setPostPerPage(param);
      setCurrentPage(1);
      setOffSet(0);
      sessionStorage.setItem("res_per_page", param);
      urlParams.set("limit",param);
    
    var refresh = window.location.protocol + "//" + window.location.host + window.location.pathname + `?${urlParams.toString()}`;
    window.history.pushState({ path: refresh }, '', refresh);

     }

    const changeSortBy = (sortbyvalue) => {
      setIsLoading(true)
      let limit = urlParams.getAll('limit');
       if(limit.length == 0){
          limit = postsPerPage;
       } else {
          limit = Number(limit);
       }
      let pageOffset =  (currentPage - 1) * limit;
      setOffSet(pageOffset)
       setPostPerPage(limit)
      setSortByValue(sortbyvalue)
    }

    const getAuthId = async () => {
      try {
        response1 = await fetch(getHostForDA() + "/SolrApi/DistributorService/auth?ContractId=" + cid, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'ocp-apim-subscription-key': getAPIAuthKey(),  
          }
        }
      )
      .then((response) => response.json())

      return response1;
    
      } catch (error) {
        setAuthId(`1-9R05F|1-9R05G|1-9R05J|1-9R05L|1-9R06A`);
        setCustomerId(`000914`);
      } 
    }

    const getIncludesCountry = async (custId) => {
      try { 
        let response2 = await fetch(getHostForDA() + "/SolrApi/myparker/getCustomerCountry?customerId=" + custId, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'ocp-apim-subscription-key': getAPIAuthKey(),  
                 }
        }
      )
      .then((response) => response.json())
      return response2;
      } catch (error) {
        console.error("Error fetching data:", error);
      } 
    }


const fetchData = async (limit, pageOffSet, fq_tag, category, searchbox, isChecked = "", tabSwitch, catalogSelect = 1, initialCallWithCheckedItem=false) => {
  console.log("fetch data parameteresss",limit, offSet, fq_tag, category, searchbox, isChecked, tabSwitch, catalogSelect,initialCallWithCheckedItem)
  let userCId = sessionStorage.getItem("userCId");
  //cid = userCId == cid ? cid : ""
  let authid;
  let custid;
  let includeCountryId;
  if( fq_tag != "all"){
  let getnewauthresp =  userCId == cid ? await getAuthId() : [] ;
  authid = getnewauthresp !== undefined && getnewauthresp.length > 0 && getnewauthresp[0].AuthId != undefined ? getnewauthresp[0].AuthId : '';

    custid = getnewauthresp !== undefined && getnewauthresp.length > 0 ? getnewauthresp[0].CustomerId : '';
    sessionStorage.setItem("distributorId", custid);

  let getinccountry = await getIncludesCountry(custid);

    includeCountryId = getinccountry.countryCode != undefined && getinccountry.countryCode.length > 0 && getinccountry.countryCode[0] != undefined ? getinccountry.countryCode[0] : '';
  }
  console.log("ischecked",category !== "all" && !tabSwitch && !initialCallWithCheckedItem)
  const storedScrollPosition = window.scrollY;
    
  const baseUrl = getHostForDA() + `/fusionsearch/apollo/collections/ParkerDotComMain/query-profiles/myparkerdotcom-${language.toLowerCase()}/select?`;
  const params = new URLSearchParams({
    _cookie: false,
    authID: authid,
    country: '687PDC',
    customerID: custid,
    echoParams: 'all',
    facetMode: 'new',
    fq: 'countryId_ss:687PDC',

    // fq: fq_tag,
    includeCountries: includeCountryId,
 
    echoParams: 'all',
    'json.nl': 'arrarr',

    myresults: catalogSelect,

    q: searchbox,
    rows: limit == ""? postsPerPage : limit,
    sessionid: 'N/A',
    sort: sortByValue,
    start: pageOffSet == "" ? offSet : pageOffSet,
    tab: category,
    wt: 'json'
  });
  
  console.log('prevCheckedItem fetch data func', prevCheckedItem)
  if(prevCheckedItem?.length > 0 ){
    console.log('prevCheckedItem fetch data func if', prevCheckedItem)

   for(var i =1; i < prevCheckedItem.length; i++){
    // if(category == "all" && (!i[0].includes(supportpid) ||  !i[0].includes(productpid) || !i[0].includes(xrefpid))){
      params.append("fq", prevCheckedItem[i])
    // }
   } 
    fq_tag !== "skipForPagination" && fq_tag !== "UncheckedFQ"  && params.append("fq",fq_tag);
  }
  else {
      params.append("fq", fq_tag)    
  }


  /* add fq for id_s for private assets in email form*/
  let id_s = urlParams.getAll('id_s');
 
  if(id_s.length > 0){
   
    fq_tag = "id:"+ id_s;
    params.append("fq", fq_tag)
  }
 
 
  const url = `${baseUrl}?${params.toString()}`;
  
    // setIsLoading(true)
 
  try {
    console.log("paramsss", params.getAll('fq'))
    let fqList = params.getAll('fq')
    if(fqList.length > 1){
      
    const response = await fetch(url, {
      method: 'GET',      headers: {
        'Content-Type': 'application/json',
        'ocp-apim-subscription-key': getAPIAuthKey()
      }
    }
      );
    const jsonData = await response.json();
    console.log('jsonData', jsonData)

    if(searchDLToggle && category == "all"){
      searchDLToggle = false;
      TagManager.dataLayer({
        dataLayer: {
          event: 'Myparker_Search',
          page_name: 'My Parker Search Bar',
          search_term: searchBox,
          search_term_local: 'N/A',
          no_of_search_results: jsonData?.response?.numFound,
        }
       })
    }
    
    if(jsonData.response && jsonData.response.docs && jsonData.response.docs.length  > 0){
      
        setResults(jsonData.response.docs);
        setAllFacetsAPIData(jsonData.facet_counts.facet_fields)
    }else{
      
      if(category != "all"){
        setResults([]);
        setAllFacetsAPIData([])
        
        return;
      }
      if( category == "xref"){
            setAllFacets(jsonData.facet_counts.facet_fields.xrefType_facet)
      }
      else{
        setAllFacets(jsonData.facet_counts.facet_fields[`siteSection${language}_facet`])
      }
      if(category == "all" && !urlParams.has("selectCategory") || urlParams.getAll("selectCategory") == "support"){
       
        let facetFields = jsonData.facet_counts.facet_fields;
        sessionStorage.setItem("facetFields",JSON.stringify(facetFields));
        
       return facetFields;
        
      }
      if(category == "all" && urlParams.getAll("selectCategory") == "products" ){

        let facetFields = jsonData.facet_counts.facet_fields;
        //console.log("facetFields",getTabs(`siteSection${language}_facet`))
        return facetFields;
      }
      if(category == "all" && urlParams.getAll("selectCategory") == "xref" ){
       
        let facetFields = jsonData.facet_counts.facet_fields;
        //console.log("facetFields",getTabs(`siteSection${language}_facet`))
        return facetFields;
      }
    }
      setNumCount(jsonData.response.numFound)
      
  }
  } catch (error) {
   
    console.error("Error fetching data:", error);

    setError(error.message);

  } finally {
    	   
    if(category !== "all"){
      setShoNoResult("showNoResult");
    }
    window.scrollTo(0, storedScrollPosition);
    setTimeout(() => {
      setIsLoading(false)
    }, 2000)
  

  }
};



useEffect(()=>{
  
  urlParams.getAll("catalog").length == 0 && urlParams.set("catalog","mycatalog")
  history.push(`${currentUrl.pathname}?${urlParams.toString()}`);

  var limit =urlParams.getAll('limit'); 
    if(limit.length == 0){
      sessionStorage.setItem('res_per_page', postsPerPage);
    }     
    limit = sessionStorage.getItem("res_per_page");
    if(limit == null){
   
      limit = postsPerPage;
      urlParams.set("limit", limit);
      var refresh = window.location.protocol + "//" + window.location.host + window.location.pathname + `?${urlParams.toString()}`;
      //window.history.replaceState(null, null, `${window.location.origin}?${queryParams.toString()}`);
      window.history.pushState({ path: refresh }, '', refresh);
    }else{

      limit = limit;
      
    }
  const getAllData = async () => {

    let results;

    console.log("prevcheckeditem", prevCheckedItem)
    let fq_tag1 = "skipForPagination";
    if((prevCheckedItem.length == 0) || (prevCheckedItem.length == 1 && (prevCheckedItem[0].includes(supportpid) ||  prevCheckedItem[0].includes(productpid) || prevCheckedItem[0].includes(xrefpid) || prevCheckedItem[0].includes(urlParams.get(`supportType${language}_fq`)) || prevCheckedItem[0].includes(urlParams.get(`supportResourceType${language}_fq`))))){
      fq_tag1 = `{!tag=tag_siteSection${language}_fq}siteSection${language}_fq:*`;
    }
       results = await fetchData(limit, offSet, fq_tag1, 'all', searchBox, '','', catalogState, true);
             
      const newtabs =getTabs2(`siteSection${language}_facet`, results);
      console.log("sitesectionxxx1",newtabs)
      sessionStorage.setItem("supportpid",newtabs[0][0]);
      sessionStorage.setItem("productpid",newtabs[1][0]);
      sessionStorage.setItem("xrefpid",newtabs[2][0]);
      setSupportpid(getPid(newtabs[0][0]))
      setProductpid(getPid(newtabs[1][0]))
      setXrefpid(getPid(newtabs[2][0]))
 
    }
    getAllData();
},[catalogState, prevCheckedItem, searchBox])

  const updtaeCatalogState = (val) => {
    setCatalogState(val)
    clearFilter(val)
  }

  const clearFilter = (val = null) => {
    setIsLoading(true)
    urlParams.delete("search_within");
      urlParams.delete(`supportType${language}_fq`)

      urlParams.delete(`supportResourceType${language}_fq`)
      
    if(val != null) urlParams.set("catalog", val ? "mycatalog" : "general");
    history.push(`${currentUrl.pathname}?${urlParams.toString()}`);
  }


  const clearStateOnTabSwtich = (k) => {
    setPrevCheckedItem([])
    setActiveKey(k)
    setTabSwitchState(k)
  }

 async function handleSearch(k) {
  
  setIsLoading(true)
  
  if(k == "support"){
    urlParams.set("selectCategory",k);
     pid = sessionStorage.getItem("supportpid");
     urlParams.set(`siteSection${language}_fq`,getPid(pid));
  }
  if(k == "products"){
     urlParams.set("selectCategory",k);
     pid =sessionStorage.getItem("productpid")
     urlParams.set(`siteSection${language}_fq`,getPid(pid));
     urlParams.delete(`supportType${language}_fq`)
     urlParams.delete(`supportResourceType${language}_fq`)
  }

  if(k == "xref"){
    urlParams.set("selectCategory",k);
    pid = sessionStorage.getItem("xrefpid");
    urlParams.set(`siteSection${language}_fq`,getPid(pid));
    urlParams.delete(`supportType${language}_fq`)
    urlParams.delete(`supportResourceType${language}_fq`)
 }

//  fq_tag = `{!tag=tag_siteSection${language}_fq}siteSection${language}_fq:`+getPid(pid);
 setActiveKey(k);
 console.log('urlParams after chageee tabbbb', urlParams)
  var refresh = window.location.protocol + "//" + window.location.host + window.location.pathname + `?${urlParams.toString()}`;
  window.history.pushState({ path: refresh }, '', refresh);

}
function getCookie(name) {
  var cookies = document.cookie.split('; ');

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i].split('=');

    if (cookie[0] === name) {
      return cookie[1];
    }
  }
  return 'N/A';
}
useEffect(()=>{
  let cids = props.orgList.filter(item => item.cid == cid);
  console.log("mycidsss",props.orgList)
  sessionStorage.setItem('orgListWithName',cids.length > 0 ? cids[0].orgName : "");
  sessionStorage.setItem('userCompanyId',cids.length > 0 ? cids[0].legalId : "");
  sessionStorage.setItem('userCId',cids.length > 0 ? cids[0].cid : "");
 
 
    var previousURL = getCookie('previousURL');
    if (decodeURIComponent(previousURL) == window.location.href) {
       previousURL = getCookie('previousURL1');
     }
     document.cookie =
       'previousURL=' +
       encodeURIComponent(window.location.href) +
       '; domain=parker.com; path=/';
    TagManager.dataLayer({
       dataLayer: {
         event: 'page_view',
         page_name: 'Myparker Search',
         page_referrer: decodeURIComponent(previousURL),
         content_group: 'myparker: help and support',
         country: 'USA',
         language: sessionStorage.getItem('defaultLang')
           ? sessionStorage.getItem('defaultLang')
           : 'EN',
         content_owner: '687PDC',
         nav_owner: '687PDC',
         product_category_name: 'N/A',
         product_series_name: 'N/A',
         product_series_id: 'N/A',
         product_id: 'N/A',
         part_name: 'N/A',
         part_number: 'N/A',
         is_category_page: 'No',
         is_series_page: 'No',
         is_pdp: 'No',
         is_req_quote: 'No',
         is_wtb: 'No',
         is_buyable_online: 'No',
         is_configurable: 'No',
         user_ip: window.sessionStorage.getItem('ClientIPAddress'),
         user_id: window.sessionStorage.getItem('WCTrustedToken')
           ? window.sessionStorage.getItem('WCTrustedToken').split('%')[0]
           : 'N/A',
         user_authenticated: 'authenticated user - MyParker Search',
 
         user_company_name: window.sessionStorage.getItem('orgListWithName')
           ? window.sessionStorage.getItem('orgListWithName')
           : 'N/A',
         user_company_id: window.sessionStorage.getItem('userCompanyId')
           ? window.sessionStorage
               .getItem('userCompanyId')
               
           : 'N/A',
 
         // user_company_id: window.sessionStorage.getItem('orgListWithId')
         //   ? window.sessionStorage.getItem('orgListWithId').replace(/[()]/g, '')
         //   : '',
       },
     });
setIsLoading(false)

}, [])

useEffect( () => {
  if(prevCheckedItem.length === 0 && tabSwitchState){
    handleSearch(tabSwitchState)
  }
},[tabSwitchState])

const handleURLcheckedItems = (fqVal) => {
  console.log("urlParams.get('selectCategory')", urlParams.get('selectCategory'))
  let checkeditem = []
  if(fqVal !== null){
    if(!prevCheckedItem.toString().includes(fqVal)){
      checkeditem.push(fqVal)
    }  
  }
  if(urlParams.get('selectCategory') == null || urlParams.get('selectCategory') == "support"){
    let pid = urlParams.get(`supportType${language}_fq`)
    let resourceId = urlParams.get(`supportResourceType${language}_fq`)
    if (pid !== null)checkeditem.push(`{!tag=tag_supportType${language}_fq}supportType${language}_fq:`+pid)
    if (resourceId !== null)checkeditem.push(`{!tag=tag_supportResourceType${language}_fq}supportResourceType${language}_fq:`+resourceId)

    console.log('handleURLcheckedItems checkeditem', checkeditem)
  }
  setPrevCheckedItem([...prevCheckedItem, ...checkeditem])
 }

useEffect( () => {
  console.log("support useeffect",activeKey, catalogState, prevCheckedItem)
if(supportpid != "" &&  productpid != "" &&  xrefpid != ""){
  let supportTypePid = urlParams.get(`supportType${language}_fq`)
  let supportResourceTypePid = urlParams.get(`supportResourceType${language}_fq`)
  console.log('supportResourceTypePid', supportResourceTypePid, supportTypePid)
  if(catalogState){
  console.log('tag_siteSection1',prevCheckedItem.length )
  let pid = ""
  let fq_tag2 = ''

  if(activeKey == "support"){
      pid = sessionStorage.getItem("supportpid");
       fq_tag2 = `{!tag=tag_siteSection${language}_fq}siteSection${language}_fq:`+getPid(pid);
     
  }else if(activeKey == "products"){
      pid = sessionStorage.getItem("productpid");
      fq_tag2 = `{!tag=tag_siteSection${language}_fq}siteSection${language}_fq:`+getPid(pid);

  }else{
    
      pid = sessionStorage.getItem("xrefpid");
      fq_tag2 = `{!tag=tag_siteSection${language}_fq}siteSection${language}_fq:`+getPid(pid);
  }

  
  if(activeKey == "support" && supportTypePid != null && prevCheckedItem.toString().includes(supportTypePid)) {
    console.log("inside supportTypePid if", supportTypePid)
    fetchData(postsPerPage, offSet, fq_tag2, activeKey, searchBox, '', '', catalogState,true);}
  else if(activeKey == "support" && supportResourceTypePid != null && prevCheckedItem.toString().includes(supportResourceTypePid)) {
    console.log("inside supportResourceTypePid if", supportResourceTypePid)  
    fetchData(postsPerPage, offSet, fq_tag2, activeKey, searchBox, '', '', catalogState,true);}
    else if(activeKey == "support" && supportTypePid == null && supportResourceTypePid == null){
      console.log("inside else if", supportResourceTypePid, supportTypePid)  
    fetchData(postsPerPage, offSet, fq_tag2, activeKey, searchBox, '', '', catalogState,true);
  }
    else if(activeKey != "support"){
    fetchData(postsPerPage, offSet, fq_tag2, activeKey, searchBox, '', '', catalogState,true);
  }
  if(!prevCheckedItem.toString().includes(fq_tag2)){
    handleURLcheckedItems(fq_tag2)
  }
  
  history.push(`${currentUrl.pathname}?${urlParams.toString()}`);
  }
  else{
    
    var limit =urlParams.getAll('limit'); 
    if(limit.length == 0){
      sessionStorage.setItem('res_per_page', postsPerPage);
    } 
     
    limit = sessionStorage.getItem("res_per_page");
    if(limit == null){
      limit = postsPerPage;
      urlParams.set("limit", limit);
      var refresh = window.location.protocol + "//" + window.location.host + window.location.pathname + `?${urlParams.toString()}`;
      //window.history.replaceState(null, null, `${window.location.origin}?${queryParams.toString()}`);
      window.history.pushState({ path: refresh }, '', refresh);
    }else{
      limit = limit;
    }
    if (!urlParams.has('selectCategory') ||  urlParams.getAll('selectCategory') == "support") {
        
      let secureRouterReferrerPath = sessionStorage.getItem("QSI_History");
      let pref = JSON.parse(secureRouterReferrerPath);

          let fq_tag2 = `{!tag=tag_siteSection${language}_fq}siteSection${language}_fq:`+ supportpid;
          if(pref != null && pref.PageReferrer && pref.PageReferrer.includes("/search")) {
         
          if (performance.navigation.type == performance.navigation.TYPE_NAVIGATE) {
           
            let newURL = '';
            
            newURL = currentUrl.pathname+'?'+ "cid=" + cid;
            
            console.log("newurl",newURL)
            urlParams.set("selectCategory","support");
            urlParams.set(`siteSection${language}_fq`,supportpid);
            var refresh = window.location.protocol + "//" + window.location.host + window.location.pathname + `?${urlParams.toString()}`;
      window.history.pushState({ path: refresh }, '', refresh);
          }
        }
        
        console.log("mycatalogvalue",catalogState)
        if(!prevCheckedItem.toString().includes(fq_tag2)){
          handleURLcheckedItems(fq_tag2)
        }

        if(activeKey == "support" && supportTypePid != null && prevCheckedItem.toString().includes(supportTypePid)) {
          console.log("inside supportTypePid if", supportTypePid)
          fetchData(limit, offSet, fq_tag2, "support", searchBox,'','', catalogState,true);}
        else if(activeKey == "support" && supportResourceTypePid != null && prevCheckedItem.toString().includes(supportResourceTypePid)) {
          console.log("inside supportResourceTypePid if", supportResourceTypePid)  
          fetchData(limit, offSet, fq_tag2, "support", searchBox,'','', catalogState,true);}
          else if(activeKey == "support" && supportTypePid == null && supportResourceTypePid == null){
            console.log("inside else if", supportResourceTypePid, supportTypePid)  
          fetchData(limit, offSet, fq_tag2, "support", searchBox,'','', catalogState,true);
        }
      }
      if (urlParams.getAll('selectCategory') == "products" || urlParams.getAll('selectCategory') == "xref"){
  
        let secureRouterReferrerPath = sessionStorage.getItem("QSI_History");
        let pref = JSON.parse(secureRouterReferrerPath);
        
        if(pref != null && pref.PageReferrer && pref.PageReferrer.includes("/search")) {
        if (performance.navigation.type == performance.navigation.TYPE_NAVIGATE) {
          
          let tabCat = urlParams.getAll("selectCategory");
          let newURL = '';
            
            newURL = currentUrl.pathname+'?'+ "cid=" + cid;
          if(tabCat == "products"){
            pid = productpid;
            urlParams.set("selectCategory","products");
            urlParams.set(`siteSection${language}_fq`,productpid);
            var refresh = window.location.protocol + "//" + window.location.host + window.location.pathname + `?${urlParams.toString()}`;
            window.history.pushState({ path: refresh }, '', refresh);
          }
          if(tabCat == "xref"){          
            pid = xrefpid;
            urlParams.set("selectCategory","xref");
            urlParams.set(`siteSection${language}_fq`,productpid);
            var refresh = window.location.protocol + "//" + window.location.host + window.location.pathname + `?${urlParams.toString()}`;
            window.history.pushState({ path: refresh }, '', refresh);
            }
        }
      }
        limit = sessionStorage.getItem("res_per_page");
  
          if(urlParams.getAll("selectCategory") == "products"){
            pid = productpid;
            
            fq_tag = `{!tag=tag_siteSection${language}_fq}siteSection${language}_fq:`+pid;
            //window.history.pushState('', '', newURL+`&selectCategory=products&siteSection${language}_fq=`+pid);
          }
          if(urlParams.getAll("selectCategory") == "xref"){  
                  
            pid = xrefpid;
            
            fq_tag = `{!tag=tag_siteSection${language}_fq}siteSection${language}_fq:`+pid;
            //window.history.pushState('', '', newURL+`&selectCategory=xref&siteSection${language}_fq=`+pid);
            }
            
            if(!prevCheckedItem.toString().includes(fq_tag)){
              handleURLcheckedItems(fq_tag)
            }
  
            fetchData(limit, offSet, fq_tag, activeKey, searchBox, '', '', catalogState, true);
      }
      
  }
}
}, [postsPerPage, offSet, sortByValue, catalogState, prevCheckedItem, supportpid, productpid, xrefpid, activeKey, searchBox])

function getCounts(facet){

      let counts = 0;
      if(allFacets)
        counts = allFacets.filter(item => item[0] == facet).map((item, i) => {
        if(item[1] > 0){
          return item[1];
        } else {
          return "0";
        }
      
      })
      return counts;
     
    }

    function getXrefCounts(facet){

      let wildCardParam = urlParams.get(`searchbox`);
      if(wildCardParam == "*"){
        return "0";
      } else { 
      let counts = 0;
        if(allFacets)
          counts = allFacets.filter(item => item[0] == facet).map((item, i) => {
          if(item[1] > 0){
            return item[1];
          } else {
            return "0";
          }
        
        })
        return counts;

      }
     
    }

    return(
      <>

      <div className="ph-search-wrapper">
        <div className="d-none d-sm-block container-fluid">

          <Tabs activeKey={activeKey} onSelect={(k) => clearStateOnTabSwtich(k)} className="mb-5 justify-content-center ph-search-nav-tabs myparkersearch-tabs">

        <Tab eventKey="support" 

            title={`${translationUtils.getTranslation(941, "Support")} (${getCounts(sessionStorage.getItem("supportpid")) > 0 ? getCounts(sessionStorage.getItem("supportpid")) : '0'})`} disabled={`${getCounts(sessionStorage.getItem("supportpid"))}` > 0 ? false : true} >

            
              { activeKey == "support" && <Results 
              tabResult="support" 
              resultInfo={results} 
              sortBy={changeSortBy} 
              onfetchData = {fetchData} 
              allFacetsData = {allFacetsAPIData} 
              prevCheckedItem = {prevCheckedItem} 
              setPrevCheckedItem = {setCheckedfromChild}
              clearFilter = {clearFilter} 
              setSearchBox = {setSearchBox}
              language={language}
              updtaeCatalogState = {updtaeCatalogState}
              isLoading = {isLoading}
              setIsLoading = {setIsLoading}
              isCount = {getCounts(sessionStorage.getItem("supportpid"))}
              showNoResult = {showNoResult}
              />
          }
          {results.length > 0  && (
              <div>

              <Pagination resultInfo={results} currentPage={currentPage} totalPosts={getCounts(sessionStorage.getItem("supportpid"))} postsPerPage={postsPerPage} paginate={paginate} resultperPage={resultPerPage}/>

              </div>
              )}
          </Tab>
          <Tab eventKey="products" 

            title={`${translationUtils.getTranslation(218, "Products")} (${getCounts(sessionStorage.getItem("productpid")) > 0 ? getCounts(sessionStorage.getItem("productpid")) : '0'})`} disabled={`${getCounts(sessionStorage.getItem("productpid"))}` > 0 ? false : true} >

          { activeKey == "products" && <Results 
              tabResult="products" 
              resultInfo={results} 
              sortBy={changeSortBy} 
              onfetchData = {fetchData} 
              allFacetsData = {allFacetsAPIData} 
              prevCheckedItem = {prevCheckedItem} 
              setPrevCheckedItem = {setCheckedfromChild}
              clearFilter = {clearFilter} 
              language={language}
              updtaeCatalogState = {updtaeCatalogState}
              setSearchBox = {setSearchBox}
              isLoading = {isLoading}
              setIsLoading = {setIsLoading}
              isCount = {getCounts(sessionStorage.getItem("productpid"))}
              showNoResult = {showNoResult}
              />
        }
              {results.length > 0  && (
              <div>

              <Pagination resultInfo={results} currentPage={currentPage} totalPosts={getCounts(sessionStorage.getItem("productpid"))} postsPerPage={postsPerPage} paginate={paginate} resultperPage={resultPerPage}/>

              </div>
              )}
          
          </Tab>
          

          <Tab eventKey="xref"
        

            title={ `${translationUtils.getTranslation(2112, "Cross Reference")} (${getXrefCounts(sessionStorage.getItem("xrefpid")) > 0 ? getXrefCounts(sessionStorage.getItem("xrefpid")) : '0'})`} disabled={`${getXrefCounts(sessionStorage.getItem("xrefpid"))}` > 0 ? false :true} >

          { activeKey == "xref" && <Results 
              tabResult="xref" 
              resultInfo={results} 
              sortBy={changeSortBy} 
              onfetchData = {fetchData} 
              allFacetsData = {allFacetsAPIData} 
              prevCheckedItem = {prevCheckedItem} 
              setPrevCheckedItem = {setCheckedfromChild}
              clearFilter = {clearFilter} 
              language={language}
              updtaeCatalogState = {updtaeCatalogState}
              setSearchBox = {setSearchBox}
              isLoading = {isLoading}
              setIsLoading = {setIsLoading}
              showNoResult = {showNoResult}
              />
          }
              {results.length > 0 && (
      <div>

      <Pagination resultInfo={results} currentPage={currentPage} totalPosts={getXrefCounts(sessionStorage.getItem("xrefpid"))} postsPerPage={postsPerPage} paginate={paginate} resultperPage={resultPerPage}/>

      </div>
      
              )}

            </Tab>
            
          </Tabs>
        
          </div>
          <div className="tabbable hide-smallscreen">
        
          <div className="row">
            
          <div className="mob-tab col-sx-12">

          <select className="mb10 form-control visible-xs mobile-tabs" value={activeKey} onChange={(e) => setActiveKey(e.target.value)}>
          <option value="support" disabled={getCounts(sessionStorage.getItem("supportpid")) > 0 ? false : true}>{`${translationUtils.getTranslation(941, "Support")} (${getCounts(sessionStorage.getItem("supportpid")) > 0 ? getCounts(sessionStorage.getItem("supportpid")) : '0'})`}</option>
              <option value="products" disabled={getCounts(sessionStorage.getItem("productpid")) > 0 ? false : true}>{`${translationUtils.getTranslation(218, "Products")} (${getCounts(sessionStorage.getItem("productpid")) > 0 ? getCounts(sessionStorage.getItem("productpid")) : '0'})`}</option>
              <option value="xref" disabled={getXrefCounts(xrefpid) > 0 ? false : true}>{`${translationUtils.getTranslation(2112, "Cross Reference")} (${getXrefCounts(xrefpid) > 0 ? getXrefCounts(xrefpid) : '0'})`}</option>
              

          </select>
          
              {activeKey === 'support' && (
                <div>
                  <Results 
                  tabResult="support" 
                  
                  resultInfo={results} 
                  sortBy={changeSortBy} 
                  onfetchData = {fetchData} 
                  allFacetsData = {allFacetsAPIData} 
                  prevCheckedItem = {prevCheckedItem} 
                  setPrevCheckedItem = {setCheckedfromChild}
                  clearFilter = {clearFilter}
                  language={language}
                  updtaeCatalogState = {updtaeCatalogState}
                  setSearchBox = {setSearchBox}
                  isLoading = {isLoading}
              setIsLoading = {setIsLoading}
            />
                  {results.length > 0 && (
                <div>

            <Pagination resultInfo={results} currentPage={currentPage} totalPosts={getCounts(sessionStorage.getItem("supportpid"))} postsPerPage={postsPerPage} paginate={paginate} resultperPage={resultPerPage}/>

              </div>
              )}
                </div>
              )}
              {activeKey === 'products' && (
                <div>
                <Results 
                tabResult="products" 
                resultInfo={results} 
                sortBy={changeSortBy} 
                onfetchData = {fetchData} 
                allFacetsData = {allFacetsAPIData} 
                prevCheckedItem = {prevCheckedItem}
                setPrevCheckedItem = {setCheckedfromChild}
                clearFilter = {clearFilter}
                language={language}
                updtaeCatalogState = {updtaeCatalogState}
                setSearchBox = {setSearchBox}
                isLoading = {isLoading}
              setIsLoading = {setIsLoading}
              />
                {results.length > 0 && (
                <div>

              <Pagination resultInfo={results} currentPage={currentPage} totalPosts={getCounts(sessionStorage.getItem("productpid"))} postsPerPage={postsPerPage} paginate={paginate} resultperPage={resultPerPage}/>

              </div>
              )}
                </div>
              )}
              {activeKey === 'xref' && (
                <div>
                  <Results 
                  tabResult="xref" 
                  resultInfo={results} 
                  sortBy={changeSortBy} 
                  onfetchData = {fetchData} 
                  allFacetsData = {allFacetsAPIData} 
                  prevCheckedItem = {prevCheckedItem}
                  setPrevCheckedItem = {setCheckedfromChild}
                  clearFilter = {clearFilter}
                  language={language}
                  updtaeCatalogState = {updtaeCatalogState}
                  setSearchBox = {setSearchBox}
                  isLoading = {isLoading}
              setIsLoading = {setIsLoading}
              />
                  {results.length > 0 && (
                <div>

              <Pagination resultInfo={results} currentPage={currentPage} totalPosts={getXrefCounts(sessionStorage.getItem("xrefpid"))} postsPerPage={postsPerPage} paginate={paginate} resultperPage={resultPerPage}/>

              </div>
              )}
                </div>
              )}

          </div>
          </div>
        
          </div>
          
      </div>

      </>
    )
}