import React, { useState, useEffect } from 'react';
import * as translationUtils from '../../../common-utils/translation_utils';
import '../css/apiKeyPage.css';
import Multiselect from 'multiselect-react-dropdown';
import { use } from 'react';

const CreateApiKey = (props) => {
  const [apiKeyName, setApiKeyName] = useState('');
  const [apiKeyEmail, setApiKeyEmail] = useState('');
  const [apiKeySubscription, setApiKeySubscription] = useState([]);
  const [defaultSelectedSubKeys, setDefaultSelectedSubKeys] = useState([]);
  const [errors, setErrors] = useState({});

  const [subKeyDropDownValues, setSubKeyDropDownValues] = useState([]);

  useEffect(() => {
      console.log('props', props?.subKeyList)
      let formattedSubKeyList = props?.subKeyList?.map(key => ({ label: key.value, value: key.id }));
      setSubKeyDropDownValues(formattedSubKeyList);
      
      if(props?.selectedAction == 'edit'){
        setApiKeyName(props?.editPopUpData?.apiKeyName);
        setApiKeyEmail(props?.editPopUpData?.emailAddress);
        setApiKeySubscription(props?.editPopUpData?.keyProductMappings?.map(key => key.productId));
        let selectedSubKeys = props?.editPopUpData?.keyProductMappings?.map(key => ({ label: key.productName, value: key.productId }));
        setDefaultSelectedSubKeys(selectedSubKeys);
        setErrors({apiKeyName: '',
          apiKeyEmail: '',
          apiKeySubscription: ''});
      }else{
        setApiKeyName('');
        setApiKeyEmail('');
        setErrors({apiKeyName: '',
          apiKeyEmail: '',
          apiKeySubscription: ''});
      }
      document.querySelector('#createApiKeyButton').click(); 
  
  }, []);
  
  const validateForm = () => {
    let errors = {};
    let formIsValid = true;

    if (!apiKeyName) {
      formIsValid = false;
      errors['apiKeyName'] = 'Please enter a name for this API key';
    }

    if (!apiKeyEmail) {
      formIsValid = false;
      errors['apiKeyEmail'] = 'Please enter an Email address';
    } else if (!/\S+@\S+\.\S+/.test(apiKeyEmail)) {
      formIsValid = false;
      errors['apiKeyEmail'] = 'Please enter a valid Email address';
    }

    if (apiKeySubscription.length === 0) {
      formIsValid = false;
      errors['apiKeySubscription'] = 'Please select atleast one product';
    }
    setErrors(errors);
    return formIsValid;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateForm()) {
      if(props.selectedAction == 'edit' ){
        let subKeyList = apiKeySubscription.map((item) => ({ productId: item }));
        console.log('props', props?.editPopUpData);
        let data = { 'apiKeyId': props?.editPopUpData.apiKeyId,
          'apiKeyName': apiKeyName, 
          'lastModifiedBy':  JSON.parse(localStorage.getItem('okta-token-storage'))?.idToken?.claims?.email, 
          'keyProductMappings': subKeyList 
        };
        console.log('data', data);
        props.handleEditApiKey(data);
      }else{
        let subKeyList = apiKeySubscription.map((item) => ({ productId: item }));
        let data = { 'apiKeyName': apiKeyName, 'apiKeyEmail': apiKeyEmail, 'apiKeySubscription': subKeyList };
        console.log('data', data);
        props.handleCreateApiKey(data);
      }
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === 'apiKeyName') {
      setApiKeyName(value);
    } else if (name === 'apiKeyEmail') {
      setApiKeyEmail(value);
    }
    setErrors({ ...errors, [name]: false });
  };

  const handleCheckboxChange = (selectedList) => {
    let selectedValues = selectedList?.map((item) => item.value);
    setApiKeySubscription(selectedValues);
    setErrors({ ...errors, 'apiKeySubscription': false });
  };

  return (
    <div>
       <button
        style={{ float: 'right', display:"none" }}
        type="button"
        id="createApiKeyButton"
        className="button primary-button round-button medium-button ml-1"
        data-toggle="modal"
        data-target="#createApiKeyModal"
      >
      </button>
      <div
        className="ph-modal fade"
        id="createApiKeyModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="Create ApiKey Modal"
        aria-hidden="true"
        style={{ paddingTop: '15px' }}
        data-backdrop="static"  // Prevents closing on outside click
        data-keyboard="false"   // Prevents closing on Escape key
      >
        <div className="modal-dialog" role="document">
          <div className="ph-modal__content">
            <div className="d-flex justify-content-end m-0 p-0">
              <span
                data-dismiss="modal"
                aria-label="Close"
                className="ph-modal__header--close ph-modal-header-close-font-size"
                aria-hidden="true"
                onClick={() => props.showCreatePopup(false)}
              >
                <i className="fas fa-times"></i>
              </span>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="ph-modal__header">
                <h2 className="ph-modal__title" id="importuserModalLabel">
                  {props.selectedAction == 'edit' ? `${translationUtils.getTranslation(1167, 'Edit')}`
                   : `${translationUtils.getTranslation(1350, 'Create')}`} API Key
                </h2>
              </div>
              <div className="ph-modal__body">
                <div className="ph-form-import-row mt-2 pb-0">
                  <label className="ph-form-row__label">
                    {'What do you want your API Key to be named?'}
                  </label>
                  <input
                    name="apiKeyName"
                    className="ph-form-row__input mb-0"
                    type="text"
                    placeholder="Enter a name for this API key..."
                    onChange={handleInputChange}
                    value={apiKeyName}
                  />
                  {errors.apiKeyName && <p style={{ color: 'red', marginBottom:"0" }}>{errors.apiKeyName}</p>}
                </div>
                <div className='ph-form-import-row pb-0'>
                  <label className='ph-form-row__label' >
                      Email Address (API key generated for whom?)
                  </label>
                  <input
                      name='apiKeyEmail'
                      className='ph-form-row__input mb-0'
                      type='email'
                      placeholder='Enter an email address (The user should associated with MyParker)'
                      onChange={handleInputChange}
                      value={apiKeyEmail} 
                      disabled={props.selectedAction == 'edit'}/>
                      {errors.apiKeyEmail && <p style={{ color: 'red', marginBottom:"0" }}>{errors.apiKeyEmail}</p>}
                </div>
                <div className='row pb-0 multiSelectDropdown' style={{flexDirection:'column',padding:'8px 1rem 0 1rem'}}>
                  <label className='ph-form-row__label' htmlFor='apiKeySubscription'>
                      API Subscription
                  </label>
                   <Multiselect
                   id="apiKeySubscription"
                      displayValue='label'
                      onKeyPressFn={function noRefCheck(){}}
                      onRemove={handleCheckboxChange}
                      onSearch={function noRefCheck(){}}
                      onSelect={handleCheckboxChange}
                      options={subKeyDropDownValues}
                      showCheckbox
                      placeholder="Choose a product that requires an API subscription"
                      avoidHighlightFirstOption={true}
                      selectedValues={defaultSelectedSubKeys} // Ensure no options are selected by default
                    />
                    {errors.apiKeySubscription && <p style={{ color: 'red', marginBottom:"0", padding:'0' }}>{errors.apiKeySubscription}</p>}
                </div>
                <div className='ph-form-import-row pb-0'>
                  {props.showErrorMessage &&<h6 id="error_message"  className='text-danger mx-1 mt-1'>
                    {translationUtils.getTranslation(
                          2213,
                          'Error occured, Please try again!')}
                          </h6>}
                </div>
              </div>
              <div className="ph-modal__footer">
                <button
                  type="submit"
                  id="apiKeyPopupCloseButton"
                  className="button accent-button round-button medium-button small-button"
                  data-dismiss="modal"
                  onClick={() => props.showCreatePopup(false)}
                >
                  {translationUtils.getTranslation(676, 'Close')}
                </button>
                
                  <button
                    type="submit"
                    className="button primary-button round-button medium-button ml-1"
                    // onClick={props.handleCreateToken}
                  >
                    {props.selectedAction == 'edit' ? `${translationUtils.getTranslation(1167, 'Edit')}`
                   : `${translationUtils.getTranslation(1350, 'Create')}`}
                  </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateApiKey;