import React, { useEffect } from 'react';
import * as translationUtils from '../../../common-utils/translation_utils';

const ConfirmationPopUp = (props) => {
  useEffect(() => {
    document.getElementById('apiKeyConfirmationModalButton').click();
  },[]);
  return (
    <div>
      <button
        style={{ float: 'right', display:"none" }}
        type="button"
        id="apiKeyConfirmationModalButton"
        className="button primary-button round-button medium-button ml-1"
        data-toggle="modal"
        data-target="#confirmationModal"
      >
      </button>
      <div
        className="ph-modal fade"
        id="confirmationModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="Create Api Key Modal"
        aria-hidden="true"
        data-backdrop="static"  // Prevents closing on outside click
        data-keyboard="false"   // Prevents closing on Escape key
      >
        <div className="modal-dialog" role="document">
          <div className="ph-modal__content">
            <div className="d-flex justify-content-end m-0 p-0">
              <span
                data-dismiss="modal"
                aria-label="Close"
                className="ph-modal__header--close ph-modal-header-close-font-size"
                aria-hidden="true"
                id="apiKeyCloseConfirmationModal"
                onClick={() => props.showConfirmationPopUp(false)} 
              >
                <i className="fas fa-times"></i>
              </span>
            </div>
            <div className="ph-modal__header">
              {/*<h2 className="ph-modal__title" id="importuserModalLabel">
                    {"Are you sure you want to deactivate toke?"}
                  </h2>*/}
            </div>
            <div className="ph-modal__body">
              <div className="ph-form-import-row text-c">
              Are you sure you want to deactivate <b >{props?.apiKey?.apiKeyName}</b> Api Key?
              </div>
            </div>
            <div className="ph-modal__footer">
              <button
                type="button"
                className="button accent-button round-button medium-button small-button"
                data-dismiss="modal"
                onClick={() => props.showConfirmationPopUp(false)} 
              >
                {translationUtils.getTranslation(404, 'Cancel')}
              </button>
              
                <button
                  type="button"
                  className="button primary-button round-button medium-button ml-1"
                  data-dismiss="modal"
                  onClick={() => props.handleDeactivateApiKey(props?.apiKey?.apiKeyId)}
                >
                  {translationUtils.getTranslation(1889, 'Confirm')}
                </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationPopUp;