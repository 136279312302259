import React, {useState, useEffect} from "react";
import { useHistory } from "react-router-dom";
import * as translationUtils from '../../../common-utils/translation_utils';

// import { listInputValue, getCategoryType, categoriesOrder, listLabel, getPid, getTabs2 } from '../utils/FacetKeys';


export function FilterSidebar(props){
    const history = useHistory();
    const currentUrl = new URL(window.location.href);
    const urlParams = new URLSearchParams(currentUrl.search);
    const language = props.language;
    let resourceFromUrl = urlParams.get(`supportResourceType${language}_fq`)
  const tabs = props.filteTab;
  const [isExpanded, setIsExpanded] = useState(false);
  const [searchWithinInputValue, setSearchWithinInputValue] = useState('');
  const [filterProductInputValue, setFilterProductInputValue] = useState('');
  const [filterDivisionInputValue, setFilterDivisionInputValue] = useState('');
  const [facetStateData, setFacetStateData] = useState([]);
  const [productFacetFilterlist, setProductFacetFilterlist] = useState([]);
  const [DivisionFacetFilterlist, setDivisionFacetFilterlist] = useState([]);

  const [divisionSupportFacetFilterlist, setDivisionSupportFacetFilterlist] = useState([])

  const [materialFacetFilterlist, setMaterialFacetFilterlist] = useState([]);
  const [industryFacetFilterlist, setIndustryFacetFilterlist] = useState([]);
  const [brandFacetFilterlist, setBrandFacetFilterlist] = useState([]);
  const [categoryFacetFilterlist, setCategoryFacetFilterlist] = useState([]);
  const [isExpandedDivision, setIsExpandedDivision] = useState(false);
  const [resourceIsExpanded, setResourceIsExpanded] = useState();
  const [isExpandedCrossrefType, setIsExpandedCrossrefType] = useState(false);

  const [isExpandedCrossrefDivision, setIsExpandedCrossrefDivision] = useState(false)
  const [isExpandedCrossrefManufacturer, setIsExpandedCrossrefManufacturer] = useState(false)


  const [supportFacetFilterList, setSupportFacetFilterList] = useState([]);
  const [resourceTypeFacetFilterList, setResourceTypeFacetFilterList] = useState([]);

  const [technologyFacetFilterlist, setTechnologyFacetFilterlist] = useState([]); 

  const [cADFacetFilterlist, setCADFacetFilterlist] = useState([]);
  const [fileTypeFacetFilterlist, setFileTypeFacetFilterlist] = useState([]);
  const [manufacturerFacetFilterlist, setManufacturerFacetFilterlist] = useState([]);

  const [crossrefTypeFacetFilterlist, setCrossrefTypeFacetFilterlist] = useState([]); 
  const [crossrefDivisionFacetFilterlist, setCrossrefDivisionFacetFilterlist] = useState([]);
  
  const [selectedCatalog, setSelectedCatalog] = useState(urlParams.getAll("catalog") == "general" ? 0 : 1); 


  let catField = urlParams.getAll('selectCategory');

  if(!catField.length > 0){
    catField = 'support';
  }


  const handleInputValue = (event) => {
    console.log('event on search within', event.target.value)
    setSearchWithinInputValue(event.target.value);
    if(event.target.value == "") {
        props.setSearchBox(urlParams.get("searchbox"))
        urlParams.set("search_within", "");
        history.push(`${currentUrl.pathname}?${urlParams.toString()}`);
    }
  }

  const handleFilterByClearAll = (e) => {
    setSearchWithinInputValue("");
    props.setClearAll()
  }

  const handleCloseFilterSideBar = () => {
    props.closeFilterSideBar()
  }

  const handleCatalogChange = (event) => {

    props.setIsLoading(true)
            if(event.target.value === "General Catalog"){
                urlParams.set("catalog","general")
                urlParams.delete("search_within");
                urlParams.delete(`supportType${language}_fq`)
                console.log('urlParams in general check', urlParams.toString())
                var refresh = window.location.protocol + "//" + window.location.host + window.location.pathname + `?${urlParams.toString()}`;
                window.history.pushState({ path: refresh }, '', refresh);
                setSelectedCatalog(0);
                props.catalogStateUpdate(0)
                setCheckedfromChild([], 1)
            }else {
                urlParams.set("catalog","mycatalog")
                urlParams.delete("search_within");
                urlParams.delete(`supportType${language}_fq`)
                var refresh = window.location.protocol + "//" + window.location.host + window.location.pathname + `?${urlParams.toString()}`;
                window.history.pushState({ path: refresh }, '', refresh);
                setSelectedCatalog(1);
                props.catalogStateUpdate(1)
                setCheckedfromChild([], 1)
            }        
      }; 


  const updateSearchWithinResult = async () => {
    if(searchWithinInputValue != ""){
        console.log("cmoing to update search resulttt", searchWithinInputValue)
        urlParams.set("search_within", searchWithinInputValue);
        let searchBox = urlParams.get("searchbox")
        props.setSearchBox('(' +searchBox+ ') AND (' +searchWithinInputValue+ ')')
        history.push(`${currentUrl.pathname}?${urlParams.toString()}`);
    }
  }

  const setCheckedfromChild = (newstate, pageNum) => {
    console.log('newstate come under filter call', newstate)
    props.setCheckedfromChild([...newstate], pageNum)
  }
  const onCheckBoxclick = (e) =>{
     props.setIsLoading(true)
    let eValue = e.target.value;
    console.log('eValue', eValue)
    if(eValue){
        var dynamicPID = listInputValue(e.target.value);
    }
    let selectedCatalogState = selectedCatalog
    if(selectedCatalogState){
        selectedCatalogState = 0
    }else{
        selectedCatalogState = 1
    }
    let categoryVal = e.target.name;
    console.log('categoryVal', categoryVal)
    let fq_tag1=  ''
    if(categoryVal === 'Division'){
         fq_tag1 = 'parkerDivisionName_fq:'+dynamicPID;
    
    }else if(categoryVal === 'Product') {
         fq_tag1 = `{!tag=tag_product${language}_fq}product${language}_fq:`+dynamicPID;
    }
    else if(categoryVal === 'Material') {
        fq_tag1 = `{!tag=tag_defAttDisplay_Material${language}_fq}defAttDisplay_Material${language}_fq:`+dynamicPID;
    }
    else if(categoryVal === 'Brand') {
        if(tabs === "products"){
            fq_tag1 = `{!tag=tag_defAttDisplay_Brand${language}_fq}defAttDisplay_Brand${language}_fq:`+dynamicPID;
        }else{
            fq_tag1 = `{!tag=tag_defAttDisplay_Brand${language}_fq:`+dynamicPID;
        }
    }
    else if(categoryVal === 'Industry') {

        fq_tag1 = `{!tag=tag_market${language}_fq}market${language}_fq:`+dynamicPID;
    }
    else if(categoryVal === 'Category') {
        fq_tag1 = `{!tag=tag_productTaxonomy_fq}productTaxonomy_fq:`+dynamicPID;
    }
    else if(categoryVal === 'Support') {
        fq_tag1 = `{!tag=tag_supportType${language}_fq}supportType${language}_fq:`+dynamicPID;
    }
    else if(categoryVal === 'ResourceType') {
        fq_tag1 = `{!tag=tag_supportResourceType${language}_fq}supportResourceType${language}_fq:`+dynamicPID;
    }
    else if(categoryVal === 'Technology') {
        fq_tag1 = `{!tag=tag_technology${language}_fq}technology${language}_fq:`+dynamicPID;
    }
    else if(categoryVal === 'CAD') {
        fq_tag1 = `{!tag=tag_cadType_fq}cadType_fq:`+dynamicPID;

    }
    else if(categoryVal === 'FileType') {
        fq_tag1 = `{!tag=tag_fileType_fq}fileType_fq:`+dynamicPID;

    }
    else if(categoryVal === 'LanguageName') {
        fq_tag1 = '{!tag=tag_languageName_fq}languageName_fq:'+dynamicPID;

    }
    else if(categoryVal === 'Manufacturer') {
        fq_tag1 = '{!tag=tag_xrefManufacturer_fq}xrefManufacturer_fq:'+dynamicPID;

    }
    else if(categoryVal === 'xrefType') {
        fq_tag1 = '{!tag=tag_xrefType_fq}xrefType_fq:'+dynamicPID;

    }
    if(e.target.checked){
        console.log('props.prevCheckedItem on checked', props.prevCheckedItem)
        let newCheckedItem = [...props.prevCheckedItem, fq_tag1]
        console.log('newCheckedItem on checked', newCheckedItem)
        setCheckedfromChild([...newCheckedItem], 1)
    }else{
        if(dynamicPID == urlParams.get(`supportType${language}_fq`)){
            urlParams.delete(`supportType${language}_fq`)
            history.push(`${currentUrl.pathname}?${urlParams.toString()}`);
        }
        const index = props.prevCheckedItem.indexOf(fq_tag1)
        console.log("fq_tagggcheck",index )
        if(index !== -1){
            console.log('filtersidebarrrprops.prevCheckedItem', props.prevCheckedItem, fq_tag1)
            let newCheckedItem = [...props.prevCheckedItem]
            newCheckedItem.splice(index, 1)
            console.log('filtersidebarrrnewCheckedItem', newCheckedItem)
          setCheckedfromChild([...newCheckedItem], 1)
        }
    }
  }

  const handleCheckboxKey = (key) => {

    let KeyData = Array.isArray(key)
    let label = ''
    if(KeyData) {
       let hasSep1 = key[0].includes('/$$$'),
            hasSep2 = key[0].includes('$$$');
 
        if (hasSep1) {
            label = key[0].split('/$$$')[1];
        } else if (hasSep2) {
            label = key[0].split('$$$')[0];
        }
    }
    const find = props?.prevCheckedItem?.find((val)=> val.includes(label))    
    // console.info("label check", label+'_'+key+'_',props.prevCheckedItem, find)
    return find;
  }

  const listInputValue = (key) => {
    let KeyData = Array.isArray(key)
    let label = ''
    if(KeyData) {
       let hasSep1 = key[0].includes('/$$$'),
            hasSep2 = key[0].includes('$$$');
 
        if (hasSep1) {
            label = key[0].split('/$$$')[0];
            if(label.includes('0/')){
                label = label.split('0/')[1];
            }
        } else if (hasSep2) {
            label = key[0].split('$$$')[1];
        }
    }else {
        let hasSep1 = key.includes('/$$$'),
        hasSep2 = key.includes('$$$');
 
        if (hasSep1) {
            label = key.split('/$$$')[1];
            if(label.includes(',')){
                label = label.split(',')[0];
            }
        } else if (hasSep2) {
            label = key.split('$$$')[0];
        }
    }
    return label;
  }

  useEffect( () => {

    console.log("tabsproducts",props.allFacetsData)

   if(tabs == "support"){
    setSupportFacetFilterList(props.allFacetsData[`supportType${language}_facet`]);
    setResourceTypeFacetFilterList(props.allFacetsData[`supportResourceType${language}_facet`]);
    setIndustryFacetFilterlist(props.allFacetsData[`market${language}_facet`]);
    setTechnologyFacetFilterlist(props.allFacetsData[`technology${language}_facet`]);
    setCADFacetFilterlist(props.allFacetsData.cadType_facet);
    setFileTypeFacetFilterlist(props.allFacetsData?.fileType_facet);
    setDivisionSupportFacetFilterlist(props.allFacetsData?.parkerDivisionName_facet);
    setBrandFacetFilterlist(props.allFacetsData[`defAttDisplay_Brand${language}_facet`]);
 
   }else if(tabs == "products"){

    setFacetStateData(props.allFacetsData);
    setProductFacetFilterlist(props.allFacetsData[`product${language}_facet`]);
    setDivisionFacetFilterlist(props.allFacetsData?.parkerDivisionName_facet);
    setMaterialFacetFilterlist(props.allFacetsData[`defAttDisplay_Material${language}_facet`]);
    setIndustryFacetFilterlist(props.allFacetsData[`market${language}_facet`]);
    setBrandFacetFilterlist(props.allFacetsData[`defAttDisplay_Brand${language}_facet`]);
    setCategoryFacetFilterlist(props.allFacetsData?.productTaxonomy_facet);
    console.log("categoryFacetFilterlist",categoryFacetFilterlist)
   }else if (tabs == "xref"){


    setManufacturerFacetFilterlist(props.allFacetsData?.xrefManufacturer_facet);
    setCrossrefTypeFacetFilterlist(props.allFacetsData?.xrefType_facet);

    setCrossrefDivisionFacetFilterlist(props.allFacetsData?.parkerDivisionName_facet);

   }

},[props.allFacetsData]);

useEffect( () => {

    setSearchWithinInputValue('')
}, [props.clearInputSearchValue])

  useEffect(() => {


    const filteredProducts = facetStateData[`product${language}_facet`]?.filter((e) => 

        e[0].toLowerCase().includes(filterProductInputValue?.toLowerCase())
    ) || props.allFacetsData[`product${language}_facet`]
    setProductFacetFilterlist(filteredProducts)
  },[filterProductInputValue])

  useEffect(() => {

    const filteredDivision = facetStateData?.parkerDivisionName_facet?.filter((e) => 

        e[0].toLowerCase().includes(filterDivisionInputValue?.toLowerCase())
    ) || props.allFacetsData?.parkerDivisionName_facet
    setDivisionFacetFilterlist(filteredDivision)
  },[filterDivisionInputValue])

    return (
        <div className="filter-area">

          {tabs == "support" && 

          <div className="main-filter-div">
        <div id="accordion">
            <div className="product-card">

                {props?.resultInfo?.length > 0 ?

                    <div className="sidehead-box"> {translationUtils.getTranslation(1516, "Search in Results")} </div> :

                    <div className="sidehead-box"> {translationUtils.getTranslation(83, "Results")} (0) </div>
                }
                <div className="card-header search-box" id="">

                <div class="srch-input"><input type="text" id="txt_search_within" placeholder={translationUtils.getTranslation(46, "Search")} value={searchWithinInputValue} onChange={handleInputValue}/></div>

                <div className="srch-btn"><button class="btn" type="button" onClick={updateSearchWithinResult}><i class="fa fa-search"></i></button>
                </div>

                </div>
                <span className="break-line"><hr /></span>
            </div>
            <div className="card mproduct-list filter-results">
        <div className="sidehead-box filterresults-head-box"> {translationUtils.getTranslation(1006, "Filter Results")} </div>
               <div className="card-header " id="">
                <ul className="radio_label filter-label">
                    <li>
                       

                       <input type="checkbox" class="form-checkbox h-4  gen_catalog" id="gen_catalog" name="catalog"  value="General Catalog" checked={!selectedCatalog}  onChange={(e) => handleCatalogChange(e)}/>
                       <label htmlFor="gen_catalog" className=""> {translationUtils.getTranslation(1867, "General Catalog")}</label>

                   </li>
                   <li>

                       <input type="checkbox" class="form-checkbox h-4  gen_catalog" id="gen_catalog" name="catalog" value="My Catalog" checked={selectedCatalog} onChange={(e) => handleCatalogChange(e)}/>
                       <label htmlFor="my_catalog" className=""> {translationUtils.getTranslation(1866, "My Catalog")}</label>

                   </li>
                 </ul>
                </div>
            </div>
            {props?.resultInfo?.length > 0 &&
            <div>
            {/* <div className="card mproduct-list product-card filter-results">
        <div className="sidehead-box filterresults-head-box"> {translationUtils.getTranslation(1006, "Filter Results")} </div>
               <div className="card-header " id="">
                <ul className="radio_label filter-label">
                    <li>
                       

                       <input type="checkbox" class="form-checkbox h-4  gen_catalog" id="gen_catalog" name="catalog"  value="General Catalog" checked={!selectedCatalog}  onChange={(e) => handleCatalogChange(e)}/>
                       <label htmlFor="gen_catalog" className=""> {translationUtils.getTranslation(1867, "General Catalog")}</label>

                   </li>
                   <li>

                       <input type="checkbox" class="form-checkbox h-4  gen_catalog" id="gen_catalog" name="catalog" value="My Catalog" checked={selectedCatalog} onChange={(e) => handleCatalogChange(e)}/>
                       <label htmlFor="my_catalog" className=""> {translationUtils.getTranslation(1866, "My Catalog")}</label>

                   </li>
                 </ul>
                </div>
            </div> */}
            <div className="product-list product-card">
        <div class="sidebar-search-box-container">
       
           

        <div className="sidehead-box"> 


        <span className="filterby hide-mobile">{translationUtils.getTranslation(1420, "Filter By")}</span>
        <span className="filterby hide-largescreen">{translationUtils.getTranslation(1153, "Filters")}</span>

        <label className="filterby_clear_all hide-mobile" style={{ cursor: 'pointer' }} id="ClearSearch" onClick={handleFilterByClearAll}>{translationUtils.getTranslation(912, "Clear All")}</label> 

        </div>
            <div className="support-type">
                <details open>
                    <summary>{translationUtils.getTranslation(1529, "Support Type")}</summary>
                    <ul>
                       
                        {supportFacetFilterList?.length > 0 && supportFacetFilterList?.map( (item, index) => {
                            let isChecked = handleCheckboxKey(item);
                            isChecked == undefined ? isChecked =false : isChecked = true;
                            return(<li class="text-sm filter-label" key={index}> <label class="flex items-center text-sm ">
                                <input type="checkbox" class="form-checkbox h-4 " key={index} name = 'Support' checked={isChecked} value={item}  onChange={(e) =>onCheckBoxclick(e)}/>
                                <span class="ml-2">{listInputValue(item)+' ('+item[1]+')'}</span>
                            </label></li>)
                        })}

                    </ul>
                </details>
            </div>

        {resourceTypeFacetFilterList?.length > 0  &&<div class="card">

            <details open={resourceFromUrl == null ? false : true}>
                <summary>{translationUtils.getTranslation(1061, "Resource Type")}</summary>
               {resourceTypeFacetFilterList && <ul>
                    {resourceTypeFacetFilterList?.map( (item, index) => {
                        let isChecked = handleCheckboxKey(item);
                        isChecked == undefined ? isChecked =false : isChecked = true;

                        return(resourceIsExpanded ?
                            <li class="text-sm filter-label"> <label class="flex items-center text-sm ">
                            <input type="checkbox" class="form-checkbox h-4 " name = 'ResourceType' checked={isChecked} value={item}  onChange={(e) =>onCheckBoxclick(e)}/>
                            <span class="ml-2">{listInputValue(item)+' ('+item[1]+')'}</span>

                        </label></li> 

                        : index < 4 && (
                        <li class="text-sm filter-label"> <label class="flex items-center text-sm ">
                            <input type="checkbox" class="form-checkbox h-4 " name = 'ResourceType' checked={isChecked} value={item}  onChange={(e) =>onCheckBoxclick(e)}/>
                            <span class="ml-2">{listInputValue(item)+' ('+item[1]+')'}</span>
                        </label></li> )

                        )
                    })}

                </ul>}
                <div className="show-expanded">
                {resourceTypeFacetFilterList?.length >3 && <a className="expand-list" onClick={() => setResourceIsExpanded(!resourceIsExpanded)}>

                {resourceIsExpanded ? <i class="fa fa-minus"></i> : <i class="fa fa-plus"></i>}

                </a>}
                {resourceTypeFacetFilterList?.length >3 && <a className="view-more-list" onClick={() => setResourceIsExpanded(!resourceIsExpanded)}>

                {resourceIsExpanded ? translationUtils.getTranslation(742,'View Less') : translationUtils.getTranslation(967,'View More')}

                </a>}
                </div>

            </details>
                   
        </div>}

        {industryFacetFilterlist?.length > 0 && <div class="card">
           <details>

                <summary>{translationUtils.getTranslation(1793, "Industry")}</summary>

                <ul>
                {industryFacetFilterlist && industryFacetFilterlist?.map( (item, index) => {
                    let isChecked = handleCheckboxKey(item);
                    isChecked == undefined ? isChecked =false : isChecked = true;
                    return(<li class="text-sm filter-label"> <label class="flex items-center text-sm filter-label">
                            <input type="checkbox" class="form-checkbox h-4 " name = 'Industry' checked={isChecked} value={item}  onChange={(e) =>onCheckBoxclick(e)}/>
                            <span class="ml-2">{listInputValue(item)+' ('+item[1]+')'}</span>
                        </label></li>)
                    })}  
                </ul>
            </details>
        </div>}
        {technologyFacetFilterlist?.length > 0 && <div class="card">
           <details>

                <summary>{translationUtils.getTranslation(24, "Technology")}</summary>

                <ul>
                    {technologyFacetFilterlist && technologyFacetFilterlist?.map( (item, index) => {
                    let isChecked = handleCheckboxKey(item);
                    isChecked == undefined ? isChecked =false : isChecked = true;
                    return(<li class="text-sm filter-label"> <label class="flex items-center text-sm filter-label">
                            <input type="checkbox" class="form-checkbox h-4 " name = 'Technology' checked={isChecked} value={item}  onChange={(e) =>onCheckBoxclick(e)}/>
                            <span class="ml-2">{listInputValue(item)+' ('+item[1]+')'}</span>
                        </label></li>)
                    })}
                </ul>
            </details>
        </div>}
        {brandFacetFilterlist?.length > 0 && <div class="card">
           <details>

                <summary>{translationUtils.getTranslation(684, "Brand")}</summary>

                <ul>
                    {brandFacetFilterlist && brandFacetFilterlist?.map( (item, index) => {
                        let isChecked = handleCheckboxKey(item);
                        isChecked == undefined ? isChecked =false : isChecked = true;
                    return(<li class="text-sm filter-label"> <label class="flex items-center text-sm filter-label">
                            <input type="checkbox" class="form-checkbox h-4 " name = 'Brand' checked={isChecked} value={item}  onChange={(e) =>onCheckBoxclick(e)}/>
                            <span class="ml-2">{listInputValue(item)+' ('+item[1]+')'}</span>
                        </label></li>)
                    })}

                </ul>
            </details>
        </div>}

        {divisionSupportFacetFilterlist?.length > 0 && <div class="card">

           <details>

                <summary>{translationUtils.getTranslation(72,"Division")}</summary>

                <ul>

                    {divisionSupportFacetFilterlist && divisionSupportFacetFilterlist.map( (item, index) => {

                            let isChecked = handleCheckboxKey(item);
                            isChecked == undefined ? isChecked =false : isChecked = true;

                        return(<li class="text-sm filter-label"> 

                            <label class="flex items-center text-sm filter-label">
                                <input type="checkbox" class="form-checkbox h-4 " name = 'Division' checked={isChecked} value={item} onChange={(e) =>onCheckBoxclick(e)}/>
                                <span class="ml-2">{listInputValue(item)+' ('+item[1]+')'}</span>
                            </label>
                        </li>)
                    })}
                </ul>
            </details>
        </div>}
        {cADFacetFilterlist?.length > 0 && <div class="card">
           <details>

                <summary>{translationUtils.getTranslation(710, "CAD")}</summary>

                <ul>
                    {cADFacetFilterlist && cADFacetFilterlist.map( (item, index) => {
                        let isChecked = handleCheckboxKey(item);
                        isChecked == undefined ? isChecked =false : isChecked = true;

                        return(<li class="text-sm filter-label"> 

                            <label class="flex items-center text-sm filter-label">
                                <input type="checkbox" class="form-checkbox h-4 " name = 'CAD' checked={isChecked} value={item} onChange={(e) =>onCheckBoxclick(e)}/>
                                <span class="ml-2">{listInputValue(item)+' ('+item[1]+')'}</span>
                            </label>
                        </li>)
                    })}
                </ul>
            </details>
        </div>}
        {fileTypeFacetFilterlist?.length > 0 && <div class="card">
           <details>

                <summary>{translationUtils.getTranslation(1154, "File Type")}</summary>

                <ul>
                    {fileTypeFacetFilterlist && fileTypeFacetFilterlist.map( (item, index) => {
                        let isChecked = handleCheckboxKey(item);
                        isChecked == undefined ? isChecked =false : isChecked = true;

                        return(<li class="text-sm filter-label"> 

                            <label class="flex items-center text-sm filter-label">

                                <input type="checkbox" class="form-checkbox h-4 " name = 'FileType' checked={isChecked} value={item} onChange={(e) =>onCheckBoxclick(e)}/>

                                <span class="ml-2">{listInputValue(item)+' ('+item[1]+')'}</span>
                            </label>
                        </li>)
                    })}
                </ul>
            </details>
        </div>}
        </div>
      </div>
      </div>
      }
      </div>
      <div className="bottom-btn">
                  <button className=" mob-btn-call btn" onClick={handleFilterByClearAll}>{translationUtils.getTranslation(912, "Clear All")}</button>
                  <button className="btn mob-btn-apply" onClick={handleCloseFilterSideBar}>{translationUtils.getTranslation(2992, "Apply")}</button>
                </div>
      </div>
      }

         {tabs == "products" && 

          <div className="main-filter-div">
        <div id="accordion ">
        <div className="product-card">
           
        {props?.resultInfo?.length > 0 ?

            <div className="sidehead-box"> {translationUtils.getTranslation(1516, "Search in Results")} </div> : 

            <div className="sidehead-box"> {translationUtils.getTranslation(83, "Results")} (0) </div>
        }

                {/* <div className="card-header" id="">
                <div className="ph-filter-group__search"><input type="text" placeholder="Search" value=""/></div> */}
                {/* <div class="text-box-area" >
                        <input type="text" placeholder="Search" value={searchWithinInputValue} onChange={handleInputValue} />
                        <button class="search_button" onClick={updateSearchWithinResult}><i class="fa fa-search"></i></button>
                </div> */}
                <div className="card-header search-box" id="">

                    <div class="srch-input"><input type="text" id="txt_search_within" placeholder={translationUtils.getTranslation(46, "Search")} value={searchWithinInputValue} onChange={handleInputValue}/></div>

                    <div className="srch-btn"><button class="btn" type="button" onClick={updateSearchWithinResult}><i class="fa fa-search"></i></button>
                    </div>

                </div>
                <span className="break-line"><hr /></span>
                </div>
       
       
                {props?.resultInfo?.length > 0 &&
           <div className="product-list product-card">

        <div class=" sidebar-search-box-container">
            <div className="sidehead-box"> <span className="filterby hide-mobile">{translationUtils.getTranslation(1420, "Filter By")}</span>
        <span className="filterby hide-largescreen">{translationUtils.getTranslation(1153, "Filters")}</span>
        <label className="filterby_clear_all hide-mobile" style={{ cursor: 'pointer' }}  onClick={handleFilterByClearAll}>{translationUtils.getTranslation(912, "Clear All")}</label></div>
            <div className="support-type">
                <details open>
                    <summary>{translationUtils.getTranslation(25,"Product")}</summary>
                    {/* <div class="ph-filter-group__search"><input type="text" placeholder="Search" value=""/></div> */}
                    <div className="search-box-product" id="">

                        <div class="srch-input"><input type="text" placeholder={translationUtils.getTranslation(46, "Search")} value={filterProductInputValue} onChange={(e) => setFilterProductInputValue(e.target.value)}/></div>

                        <div className="srch-btn"><button class="btn" type="button" ><i class="fa fa-search"></i></button>
                        </div>
                    </div>
                    <ul>

                        {productFacetFilterlist && productFacetFilterlist.map( (item, index) => {
                            let isChecked = handleCheckboxKey(item);
                            isChecked == undefined ? isChecked =false : isChecked = true;

                                return( isExpanded ? (<li class="text-sm filter-label"> 

                                <label class="flex items-center text-sm filter-label">
                                    <input type="checkbox" class="form-checkbox h-4 " name = 'Product' checked={isChecked} value={item}  onChange={(e) =>onCheckBoxclick(e)}/>
                                    <span class="ml-2">{listInputValue(item)+' ('+item[1]+')'}</span>
                                </label>

                                </li>) : 
                                index < 4 && (<li class="text-sm filter-label"> 

                                    <label class="flex items-center text-sm filter-label">
                                        <input type="checkbox" class="form-checkbox h-4 " name = 'Product' checked={isChecked} value={item} onChange={(e) => onCheckBoxclick(e)}/>
                                        <span class="ml-2">{listInputValue(item)+' ('+item[1]+')'}</span>
                                    </label>
                                </li>)

                            ) 

                        })}

                    </ul>

                    <div className="show-expanded">
                    {productFacetFilterlist?.length >3 && <a className="expand-list" onClick={() => setIsExpanded(!isExpanded)}>

                        {isExpanded ? <i class="fa fa-minus"></i> : <i class="fa fa-plus"></i>}

                    </a>}
                    {productFacetFilterlist?.length >3 && <a className="view-more-list" onClick={() => setIsExpanded(!isExpanded)}>

                        {isExpanded ? translationUtils.getTranslation(742,'View Less') : translationUtils.getTranslation(967,'View More')}

                    </a>}
                        </div>
                 </details>
                 </div>
                
        
        
        { materialFacetFilterlist?.length > 0 && <div class="card show-responsive">
            <details>
                <summary>{translationUtils.getTranslation(2101,"Material")}</summary>
                <ul>
                {materialFacetFilterlist && materialFacetFilterlist.map( (item, index) => {
                    let isChecked = handleCheckboxKey(item);
                    isChecked == undefined ? isChecked =false : isChecked = true;

                    return(<li class="text-sm filter-label"> 

                    <label class="flex items-center text-sm filter-label">
                        <input type="checkbox" class="form-checkbox h-4 " name = 'Material' checked={isChecked} value={item} onChange={(e) => onCheckBoxclick(e)}/>
                        <span class="ml-2">{listInputValue(item)+' ('+item[1]+')'}</span>
                    </label>
                    </li>)
                })}
                </ul>
            </details>

        </div>}
       
        { brandFacetFilterlist?.length > 0 && <div class="card show-responsive">
         
           <details>
                <summary>{translationUtils.getTranslation(684, "Brand")}</summary>
                <ul>

                    {brandFacetFilterlist && brandFacetFilterlist.map( (item, index) => {
                        let isChecked = handleCheckboxKey(item);
                        isChecked == undefined ? isChecked =false : isChecked = true;

                        return(<li class="text-sm filter-label"> 

                        <label class="flex items-center text-sm filter-label">
                            <input type="checkbox" class="form-checkbox h-4 " name = 'Brand' checked={isChecked} value={item} onChange={(e) => onCheckBoxclick(e)}/>
                            <span class="ml-2">{listInputValue(item)+' ('+item[1]+')'}</span>
                        </label>
                        </li>)
                    })}
                </ul>
            </details>
        </div>}
        { categoryFacetFilterlist?.length > 0 && <div class="card show-responsive">
       
           <details>
                <summary>{translationUtils.getTranslation(555,"Product Category")}</summary>
                <ul>
                {categoryFacetFilterlist && categoryFacetFilterlist.map( (item, index) => {
                    let isChecked = handleCheckboxKey(item);
                    isChecked == undefined ? isChecked =false : isChecked = true;

                    return(<li class="text-sm filter-label"> 

                    <label class="flex items-center text-sm filter-label">
                        <input type="checkbox" class="form-checkbox h-4 " name = 'Category' checked={isChecked} value={item} onChange={(e) => onCheckBoxclick(e)}/>
                        <span class="ml-2">{listInputValue(item)+' ('+item[1]+')'}</span>
                    </label>
                    </li>)
                })}
                </ul>
            </details>
        </div>}

        { DivisionFacetFilterlist?.length > 0 && <div className="card show-responsive">
            <details open>

                <summary>{translationUtils.getTranslation(72,"Division")}</summary>


                <div className="card-header search-box division-search" id="">

                        <div class="srch-input"><input type="text" placeholder={translationUtils.getTranslation(46, "Search")} value={filterDivisionInputValue} onChange={(e) => setFilterDivisionInputValue(e.target.value)}/></div>

                        <div className="srch-btn"><button class="btn" type="button" ><i class="fa fa-search"></i></button>
                        </div>
                    </div>

                <ul>
                    {DivisionFacetFilterlist && DivisionFacetFilterlist.map( (item, index) => {
                        let isChecked = handleCheckboxKey(item);
                        isChecked == undefined ? isChecked =false : isChecked = true;

                        return( isExpandedDivision ? (<li class="text-sm filter-label"> 

                            <label class="flex items-center text-sm filter-label">
                                <input type="checkbox" class="form-checkbox h-4 " name = 'Division' checked={isChecked} value={item} onChange={(e) =>onCheckBoxclick(e)}/>
                                <span class="ml-2">{listInputValue(item)+' ('+item[1]+')'}</span>
                            </label>

                        </li>) : 
                        index < 4 && (<li class="text-sm filter-label"> 

                            <label class="flex items-center text-sm filter-label">
                                <input type="checkbox" class="form-checkbox h-4 " name = 'Division' checked={isChecked} value={item} onChange={(e) =>onCheckBoxclick(e)}/>
                                <span class="ml-2">{listInputValue(item)+' ('+item[1]+')'}</span>
                            </label>
                        </li>)

                        ) 

                    })}
                </ul>

                <div className="show-expanded">

        
                    {DivisionFacetFilterlist?.length > 3 && 

                    <a className="expand-list" onClick={() => setIsExpandedDivision(!isExpandedDivision)}>
                        {isExpandedDivision ? <i class="fa fa-minus"></i> : <i class="fa fa-plus"></i>}
                    </a>}
                    {DivisionFacetFilterlist?.length > 3 && <a className="view-more-list" onClick={() => setIsExpandedDivision(!isExpandedDivision)}>
                        {isExpandedDivision ? translationUtils.getTranslation(742,'View Less') : translationUtils.getTranslation(967,'View More')}
                    </a>}
                </div>
            </details>
        </div>}
                </div>
                </div>
}
                </div>
                <div className="bottom-btn">
                  <button className=" mob-btn-call btn" onClick={handleFilterByClearAll}>{translationUtils.getTranslation(912, "Clear All")}</button>
                  <button className="btn mob-btn-apply" onClick={handleCloseFilterSideBar}>{translationUtils.getTranslation(2992, "Apply")}</button>
                </div>
     
                </div>
               
               
               
         }

         { tabs == "xref" && 

               <div className="main-filter-div">
               <div id="accordion">
                   <div className="product-card">
                   {props.resultInfo.length > 0 ?

                    <div className="sidehead-box"> {translationUtils.getTranslation(1516, "Search in Results")} </div> : 

                    <div className="sidehead-box"> {translationUtils.getTranslation(83, "Results")} (0) </div> }
                   <div className="card-header search-box" id="">

                <div class="srch-input"><input type="text" id="txt_search_within" placeholder={translationUtils.getTranslation(46, "Search")} value={searchWithinInputValue} onChange={handleInputValue}/></div>

                <div className="srch-btn"><button class="btn" type="button" onClick={updateSearchWithinResult}><i class="fa fa-search"></i></button>
                </div>

                </div>
                   </div>
                   {props.resultInfo.length > 0 &&
                   <div className="product-list product-card">
                   <div class=" sidebar-search-box-container">

              
                  
                   <div className="sidehead-box"> 

                   <span className="filterby hide-mobile">{translationUtils.getTranslation(1420, "Filter By")}</span>
        <span className="filterby hide-largescreen">{translationUtils.getTranslation(1153, "Filters")}</span>
        <label className="filterby_clear_all hide-mobile" style={{ cursor: 'pointer' }} onClick={handleFilterByClearAll}>{translationUtils.getTranslation(912, "Clear All")}</label> </div>

        { manufacturerFacetFilterlist?.length > 0 && <div className="support-type">
            <details open>
                <summary>{translationUtils.getTranslation(1784, "Manufacturer")}</summary>
                <ul>
                {manufacturerFacetFilterlist && manufacturerFacetFilterlist.map( (item, index) => {
                    let isChecked = handleCheckboxKey(item);
                    isChecked == undefined ? isChecked =false : isChecked = true;

                    return(isExpandedCrossrefManufacturer ? (<li class="text-sm filter-label"> 

                    <label class="flex items-center text-sm filter-label">
                        <input type="checkbox" class="form-checkbox h-4 " name = 'Manufacturer' checked={isChecked} value={item} onChange={(e) => onCheckBoxclick(e)}/>
                        <span class="ml-2">{listInputValue(item)+' ('+item[1]+')'}</span>
                    </label>
                    </li>) 
                    : 
                    index < 4 && (<li class="text-sm filter-label"> 
                        <label class="flex items-center text-sm filter-label">
                            <input type="checkbox" class="form-checkbox h-4 " name = 'Manufacturer' checked={isChecked} value={item} onChange={(e) => onCheckBoxclick(e)}/>
                            <span class="ml-2">{listInputValue(item)+' ('+item[1]+')'}</span>
                        </label>
                        </li>))
                    })}
                </ul>
                <div className="show-expanded">
                {manufacturerFacetFilterlist?.length > 3 && 
                    <a className="expand-list" onClick={() => setIsExpandedCrossrefManufacturer(!isExpandedCrossrefManufacturer)}>
                        {isExpandedCrossrefManufacturer ? <i class="fa fa-minus"></i> : <i class="fa fa-plus"></i>}
                    </a>}
                    {crossrefTypeFacetFilterlist?.length > 3 && <a className="view-more-list" onClick={() => setIsExpandedCrossrefManufacturer(!isExpandedCrossrefManufacturer)}>
                        {isExpandedCrossrefManufacturer ? translationUtils.getTranslation(742,'View Less') : translationUtils.getTranslation(967,'View More')}
                    </a>}
                </div>

             </details>
        </div>}
               
               
        { crossrefTypeFacetFilterlist?.length > 0 && <div class="card">
               
            <details>
                <summary>{translationUtils.getTranslation(66, "Type")}</summary>
                <ul>
                    {crossrefTypeFacetFilterlist && crossrefTypeFacetFilterlist.map( (item, index) => {
                    let isChecked = handleCheckboxKey(item);
                    isChecked == undefined ? isChecked =false : isChecked = true;

                    return(isExpandedCrossrefType ? (<li class="text-sm filter-label"> 

                    <label class="flex items-center text-sm filter-label">
                        <input type="checkbox" class="form-checkbox h-4 " name = 'xrefType' checked={isChecked} value={item} onChange={(e) => onCheckBoxclick(e)}/>
                        <span class="ml-2">{listInputValue(item)+' ('+item[1]+')'}</span>
                    </label>

                    </li>) 
                    : 
                    index < 4 && (<li class="text-sm filter-label"> 

                        <label class="flex items-center text-sm filter-label">
                            <input type="checkbox" class="form-checkbox h-4 " name = 'xrefType' checked={isChecked} value={item} onChange={(e) => onCheckBoxclick(e)}/>
                            <span class="ml-2">{listInputValue(item)+' ('+item[1]+')'}</span>
                        </label>
                        </li>))
                    })}
                </ul>
                <div className="show-expanded">

                {crossrefTypeFacetFilterlist?.length > 3 && 

                    <a className="expand-list" onClick={() => setIsExpandedCrossrefType(!isExpandedCrossrefType)}>
                        {isExpandedCrossrefType ? <i class="fa fa-minus"></i> : <i class="fa fa-plus"></i>}
                    </a>}
                    {crossrefTypeFacetFilterlist?.length > 3 && <a className="view-more-list" onClick={() => setIsExpandedCrossrefType(!isExpandedCrossrefType)}>
                        {isExpandedCrossrefType ? translationUtils.getTranslation(742,'View Less') : translationUtils.getTranslation(967,'View More')}
                    </a>}
                </div>

            </details> 

        </div>}

        { crossrefDivisionFacetFilterlist?.length > 0 && <div class="card">

             <details>
                <summary>{translationUtils.getTranslation(72,"Division")}</summary>
                <ul>

                {crossrefDivisionFacetFilterlist && crossrefDivisionFacetFilterlist.map( (item, index) => {
                        let isChecked = handleCheckboxKey(item);
                        isChecked == undefined ? isChecked =false : isChecked = true;
                        return( isExpandedCrossrefDivision ? (<li class="text-sm filter-label"> 
                            <label class="flex items-center text-sm filter-label">
                                <input type="checkbox" class="form-checkbox h-4 " name = 'Division' checked={isChecked} value={item} onChange={(e) =>onCheckBoxclick(e)}/>
                                <span class="ml-2">{listInputValue(item)+' ('+item[1]+')'}</span>
                            </label>
                        </li>) : 
                        index < 4 && (<li class="text-sm filter-label"> 
                            <label class="flex items-center text-sm filter-label">
                                <input type="checkbox" class="form-checkbox h-4 " name = 'Division' checked={isChecked} value={item} onChange={(e) =>onCheckBoxclick(e)}/>
                                <span class="ml-2">{listInputValue(item)+' ('+item[1]+')'}</span>
                            </label>
                        </li>)
                        ) 
                    })}

                </ul>

                <div className="show-expanded">
                {crossrefDivisionFacetFilterlist?.length > 3 && 
                    <a className="expand-list" onClick={() => setIsExpandedCrossrefDivision(!isExpandedCrossrefDivision)}>
                        {isExpandedCrossrefDivision ? <i class="fa fa-minus"></i> : <i class="fa fa-plus"></i>}
                    </a>}
                    {crossrefDivisionFacetFilterlist?.length > 3 && <a className="view-more-list" onClick={() => setIsExpandedCrossrefDivision(!isExpandedCrossrefDivision)}>
                        {isExpandedCrossrefDivision ? translationUtils.getTranslation(742,'View Less') : translationUtils.getTranslation(967,'View More')}
                    </a>}
                </div>

            </details>
        </div>}
             
             </div>
             </div>
            }
             </div>
             <div className="bottom-btn">
                         <button className=" mob-btn-call btn" onClick={handleFilterByClearAll}>{translationUtils.getTranslation(912, "Clear All")}</button>
                         <button className="btn mob-btn-apply" onClick={handleCloseFilterSideBar}>{translationUtils.getTranslation(2992, "Apply")}</button>
                       </div>
             </div>
         }
      </div>

    )
}