import React, { useEffect, useState } from 'react';
import moment from 'moment';
import * as translationUtils from '../../../common-utils/translation_utils';

const ApiListItem = (props) => {
  const $ = window.jQuery;
  const [showApiKeys, setShowApiKeys] = useState(false);
  const [showSubKeys, setShowSubKeys] = useState([]);
  const [isCopiedApiKey, setIsCopiedApiKey] = useState(false);
  const [isCopiedSubKey, setIsCopiedSubKey] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  useEffect(() => {
    setShowSubKeys(props?.apiKeyItem?.keyProductMappings.map(() => false));
    setIsCopiedSubKey(props?.apiKeyItem?.keyProductMappings.map(() => false));
  }, [props.apiKeyItem]);
  const handleSubKeyView = (index) => {
    console.log(index)
    console.log(showSubKeys)
    setShowSubKeys((prevShowSubKeys) =>
      prevShowSubKeys?.map((show, i) => (i === index ? !show : show))
    );
  };

  const handleApiKeyView = () => {
    setShowApiKeys(!showApiKeys);
  };

  const handleApiKeyCopy = (apiKey) => {
    navigator.clipboard.writeText(apiKey);
    setIsCopiedApiKey(true);
    setTimeout(() => setIsCopiedApiKey(false), 10000);
  }

  const handleSubKeyCopy = (index, SubKey) => {

    navigator.clipboard.writeText(SubKey);
    setIsCopiedSubKey((previsCopiedSubKey) =>
      previsCopiedSubKey?.map((copied, i) => (i === index ? true : copied))
    );
    setTimeout(() => setIsCopiedSubKey((previsCopiedSubKey) =>
      previsCopiedSubKey?.map((copied, i) => (i === index ? false : copied))
    ), 10000);
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return props.apiKeyItem.keyActiveFlag ? (
    <>
    <tr id="apiKeyEmptyRow" ><td colSpan="5" ></td></tr>
      <tr id='apikeyFirstRow' 
      >
        <td className='mobile-actions ' colSpan={1}>
          <i
            className="fa fa-edit "
            data-toggle="modal"
            data-target="#confirmationModal"
            title={`${translationUtils.getTranslation(
              1167,
              'Edit'
            )} Api Key`}
            onClick={() => {
              props.handleapiKeyAction(props.apiKeyItem, 'edit');
            }}
          />
          <i
            className="fa fa-trash"
            data-toggle="modal"
            data-target="#confirmationModal"
            title={`${translationUtils.getTranslation(
              264,
              'Deactivate'
            )} Api Key`}
            onClick={() => {
              props.handleapiKeyAction(props?.apiKeyItem, 'deactivate');
            }}
          />
        </td>
        <td  data-label="Api Key Name:" colSpan={1}>
          <b>{props?.apiKeyItem?.apiKeyName}</b>
        </td>
        <td  data-label="Created To:" colSpan={1}>
          <span  >
          {props?.apiKeyItem?.emailAddress}
          </span>
        </td>
        <td  data-label="Created By:" colSpan={1}><span>{props.apiKeyItem.createdBy}</span></td>
        <td  data-label="Last Accessed:" colSpan={1}>
          <span>{props.apiKeyItem.lastAccessed
            ? moment(props.apiKeyItem.lastAccessed).format(
                'MMM DD, YYYY hh:mm A'
              )
            : '-'}</span>
        </td>
        <td  colSpan={1}>
          <i
            className="fa fa-edit "
            data-toggle="modal"
            data-target="#confirmationModal"
            title={`${translationUtils.getTranslation(
              1167,
              'Edit'
            )} Api Key`}
            onClick={() => {
              props.handleapiKeyAction(props?.apiKeyItem, 'edit');
            }}
          />
          <i
            className="fa fa-trash"
            data-toggle="modal"
            data-target="#confirmationModal"
            title={`${translationUtils.getTranslation(
              264,
              'Deactivate'
            )} Api Key`}
            onClick={() => {
              props.handleapiKeyAction(props?.apiKeyItem, 'deactivate');
            }}
          />
        </td>
      </tr>
      <tr
        id='apikeySecondRow'
        key={props.index}
      >
         <td colSpan="5">
          <div className='row' style={{width: '100%', marginTop:'10px'}}>
            <div className='col-4 text-right align-self-center pr-0'>
              <span  >
                Api Key:
              </span>
            </div>
            <div className='col-6'>
                <input
                  type={showApiKeys ? 'text' : 'password'}
                  className="ph_apiKey_input"
                  value={props?.apiKeyItem?.apiKey}
                  readOnly
                />
                <i
                  className={showApiKeys ? "fa fa2 fa-eye-slash" : "fa fa2 fa-eye"}
                  title={`${translationUtils.getTranslation(
                    264,
                    'View'
                  )} Api Key`}
                  
                  style={{paddingRight: '0'}}
                  onClick={() => {
                    handleApiKeyView()
                  }}
                ></i>
                {isCopiedApiKey  ? (
                  <i
                    className="fa fa2 fa-check"
                    style={{paddingLeft: '0'}}
                    title={translationUtils.getTranslation(3355, 'Copied')}
                  ></i>
                ) : (
                  <i
                    className="fa fa2 fa-copy"
                    title={`${translationUtils.getTranslation(
                      2241,
                      'Copy'
                    )} Api Key`}
                    style={{paddingLeft: '0'}}
                    onClick={() => {
                      handleApiKeyCopy(props?.apiKeyItem?.apiKey);
                    }}
                  ></i>
                )}
                </div>
          </div>
          {props?.apiKeyItem?.keyProductMappings?.map((item, index) => (
            <>{index == 0 &&<div className='row' style={{width: '100%'}}>
              <div className='col-3  pr-0'>
                <div className='text-right justify-content-end' title='Expand to see Subscription keys' onClick={toggleExpand} style={{display: 'flex', flexDirection: 'row', cursor:"pointer"}}>
                  <p><b>Subscription Keys</b></p>
                  <i className={`fa fa-caret-${isExpanded ? 'up' : 'down'} mt-1`} ></i>
                  </div>
              </div>
              </div>}
          <div className={`expandable-content ${isExpanded ? 'expanded' : 'collapsed'}`}>
          <div className='row' style={{width: '100%'}}>
              
               <div className='col-4 pr-0 text-right align-self-center '>
                <span>
                {item?.productName} :
              </span></div>
              <div className='col-6'>
                <input
                type={showSubKeys[index] ? 'text' : 'password'}
                className="ph_apiKey_input"
                value={item?.apimSubscriptionKey}
                readOnly
              />
              <i
                className={showSubKeys[index] ? "fa fa2 fa-eye-slash" : "fa fa2 fa-eye"}
                title={`${translationUtils.getTranslation(
                  264,
                  'View'
                )} Api Key`}
                style={{paddingRight: '0'}}
                onClick={() => {
                  handleSubKeyView(index)
                }}
              ></i>
              {isCopiedSubKey[index]  ? (
                <i
                  className="fa fa2 fa-check"
                  style={{paddingLeft: '0'}}
                  title={translationUtils.getTranslation(3355, 'Copied')}
                ></i>
              ) : (
                <i
                  className="fa fa2 fa-copy"
                  title={`${translationUtils.getTranslation(
                    2241,
                    'Copy'
                  )} Sub Key`}
                  style={{paddingLeft: '0'}}
                  onClick={() => {
                    handleSubKeyCopy(index, item.apimSubscriptionKey);
                  }}
                ></i>)}
              </div>
          </div></div>
          </>))}
        </td>
      </tr>
    </>
  ) : (
    ''
  );
};

export default ApiListItem;