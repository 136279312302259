import React, { useState, useEffect } from "react";
import * as translationUtils from "../../../common-utils/translation_utils";
import { getMyCertificateAPI, getMyCertificateAPIAuthKey } from '../../../apiconfig.js';
import axios from "axios";

export function FilterSidebar(props) {
  const [divisionList, setDivisionList] = useState([]);
  const [docTypeList, setDocTypeList] = useState([]);
  useEffect(() => {
    const getFacetResp = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      let cid = urlParams.getAll("cid");
      let userDetails = localStorage.getItem("userDetails");
      console.log("userDetails", userDetails);
      let email = JSON.parse(userDetails).email;
      let lang = sessionStorage.getItem("defaultLang");
      let element = document.getElementById(cid);
      console.log("item from id", element);
      let customerId = element.getAttribute("data-legalid");
      await axios
        .get(
          getMyCertificateAPI() +"/getfacets?CustomerId=" +
            customerId +
            "&UserEmail=" +
            email +
            "&LangCode=" +
            lang,
          {
            headers: {
              "ocp-apim-subscription-key": getMyCertificateAPIAuthKey(),
            },
          }
        )
        .then((response) => {
          console.log("response for facet", response);
          let divisionDocype = response.data;
          let divisionArr = [];
          let docTypeArr = [];
          divisionDocype.map((data) => {
            divisionArr.push({
              label: data.Name,
              key: data.DivisionId,
              sharePointFolder: [data.Name],
              type: "division",
            });
            data.DocumentTypes.map((items) => {
              let index = docTypeArr.findIndex(
                (obj) => obj.label === items.Name
              );
              if (index !== -1) {
                docTypeArr[index].sharePointFolder.push(
                  `${data.Name}/${items.Name}`
                );
              } else {
                docTypeArr.push({
                  label: items.Name,
                  key: data.PrimaryKeyName,
                  sharePointFolder: [`${data.Name}/${items.Name}`],
                  type: "document",
                });
              }
            });
          });
          setDivisionList(divisionArr);
          setDocTypeList(docTypeArr);
        })
        .catch((error) => {
          console.log("error for facettt", error);
        });
    };
    getFacetResp();
  }, []);

  const handleFilterByClearAll = (e) => {
    props.setClearAll();
  };

  const handleCloseFilterSideBar = () => {
    props.closeFilterSideBar();
  };

  const setCheckedfromChild = (newstate, pageNum) => {
    console.log("newstate come under filter call", newstate);
    props.setCheckedfromChild([...newstate], pageNum);
  };
  const onCheckBoxclick = (e, sharePointFolder, type) => {
    let categoryVal = e.target.name;
    if (e.target.checked) {
      let newCheckedItem = [...props.prevCheckedItem, {name: categoryVal,apiPayload: sharePointFolder , type:type }];
      setCheckedfromChild([...newCheckedItem], 1);
    }
    else {
      let index = props.prevCheckedItem.findIndex(
        (obj) => obj.name === categoryVal
      );
      if (index !== -1) {
        let newCheckedItem = [...props.prevCheckedItem];
        newCheckedItem.splice(index, 1);
        setCheckedfromChild([...newCheckedItem], 1);
      }
    }
  };
  const handleCheckboxKey = (key) => {
    let find = props?.prevCheckedItem?.find((val) => val.name.includes(key));
    find == undefined ? (find = false) : (find = true);
    return find;
  };

  return (
    <div className="filter-area">
      <div className="main-filter-div">
        <div id="accordion">
          <div>
            <div className="product-card hide-mobile">
              <div className="sidehead-box sidebar-filter-heading">
                Document Search
              </div>
              <div className="card-header search-box" id="">
                <div class="srch-input">
                  <input
                    type="text"
                    id="txt_search_within"
                    placeholder={"Search for your documents here..."}
                    value={props.searchInputValue}
                    onChange={props.handleInputValue}
                    onKeyDown={(event) => props.onPressEnter(event)}
                  />
                </div>

                <div className="srch-btn">
                  <button
                    class="btn"
                    type="button"
                    onClick={props.onSearchClick}
                  >
                    <i class="fa fa-search"></i>
                  </button>
                </div>
              </div>
              <span className="break-line">
                <hr />
              </span>
            </div>
            <div className="product-list product-card">
              <div class="sidebar-search-box-container">
                <div className="sidehead-box sidehead-mobile sidebar-filter-heading">
                  <span className="filterby">
                    {translationUtils.getTranslation(1420, "Filter By")}
                  </span>

                  <label
                    className="filterby_clear_all clear-all-text"
                    style={{ cursor: "pointer" }}
                    id="ClearSearch"
                    onClick={handleFilterByClearAll}
                  >
                    {translationUtils.getTranslation(912, "Clear All")}
                  </label>
                </div>
                <div className="support-type">
                  <details open>
                    <summary>Documents</summary>
                    <ul>
                      {docTypeList?.map((item, index) => {
                        return (
                          <li class="text-sm filter-label" key={index}>
                            {" "}
                            <label class="flex items-center text-sm ">
                              <input
                                type="checkbox"
                                class="form-checkbox h-4 "
                                key={item.key}
                                name={item.label}
                                value={item.label}
                                onChange={(e) => onCheckBoxclick(e, item.sharePointFolder, item.type)}
                                checked={handleCheckboxKey(item.label)}
                              />
                              <span class="ml-2">{item.label}</span>
                            </label>
                          </li>
                        );
                      })}
                    </ul>
                  </details>
                </div>
                <div className="support-type card">
                  <details open>
                    <summary>Division</summary>
                    <ul>
                      {divisionList?.map((item, index) => {
                        return (
                          <li class="text-sm filter-label">
                            {" "}
                            <label class="flex items-center text-sm ">
                              <input
                                type="checkbox"
                                class="form-checkbox h-4 "
                                key={item.key}
                                name={item.label}
                                value={item.label}
                                onChange={(e) => onCheckBoxclick(e, item.sharePointFolder, item.type)}
                                checked={handleCheckboxKey(item.label)}
                              />
                              <span class="ml-2">{item.label}</span>
                            </label>
                          </li>
                        );
                      })}
                    </ul>
                  </details>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-btn">
          <button
            className="btn mob-btn-apply"
            onClick={handleCloseFilterSideBar}
          >
            View Results
          </button>
        </div>
      </div>
    </div>
  );
}
