import React, { useState, useEffect } from 'react';
import './apiKey.css';
import moment from 'moment';
import * as translationUtils from '../../../common-utils/translation_utils';
import CreateApiKey from './CreateApiKey';
import getApiKeyList, {
  createApiKey,
  getSubKeyList,
  updateApiKey,
  deactivateApiKey
} from '../../../services/getAPIKeyData';
import ApiListItem from './ApiListItem';
import ConfirmationPopUp from './ConfirmationPopUp';
import PopUpComponent from '../PopUpComponent';
import ApiKeyPagination from './ApiKeyPagination';

const ApiKeyMain = (props) => {
  const [showApiKey, setShowApiKey] = useState([]);
  const [copyApiKey, setCopyApiKey] = useState([]);
  const [userApiKeyList, setUserApiKeyList] = useState([]);
  const [orginalUserApiKeyList, setOrginalUserApiKeyList] = useState([]);
  const [selectedApiKey, setSelectedApiKey] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null);
  const [status, setStatus] = useState('');
  const [message, setMessage] = useState('');
  const [subKeyList, setSubKeyList] = useState([]);
  const [showCreatePopup, setShowCreatePopup] = useState(false);
  const [confirmationPopUp, setConfirmationPopUp] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [sortOrder, setSortOrder] = useState({ sortingOrder: 'asc', sortParameter: '' });
  const [editPopUpData, setEditPopUpData] = useState({});
  const [apiKeySearchValue, setApiKeySearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalPages, setTotalPages] = useState(0);
  const [showSortModal, setShowSortModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      props.getLoader(true);
      try {
        const [apiKeyList, subKeyList] = await Promise.all([getApiKeyList(), getSubKeyList()]);
        const activeApiKeyList = apiKeyList?.filter(item => item.keyActiveFlag === true);
        let sortedApiKeyList = sortApiKeyList(activeApiKeyList, 'desc', 'createdOnTimestamp');
        setOrginalUserApiKeyList(sortedApiKeyList);
        setUserApiKeyList(sortedApiKeyList);
        setSubKeyList(subKeyList);
        setTotalPages(Math.ceil(sortedApiKeyList?.length / itemsPerPage));
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        props.getLoader(false);
      }
    };
    fetchData();
  }, []);

  const sortApiKeyList = (list, order, parameter) => {
    return list?.map(item => ({
      ...item,
      createdOnTimestamp: new Date(item?.createdOn).getTime()
    }))?.sort((a, b) => order === 'asc' ? a[parameter] - b[parameter] : b[parameter] - a[parameter]);
  };

  const handleCreateApiKey = async (data) => {
    props.getLoader(true);
    try {
      const res = await createApiKey(data);
      if (res?.status) {
        document.getElementById('apiKeyPopupCloseButton').click();
        setShowCreatePopup(false);
        const getList = await getApiKeyList();
        const activeApiKeyList = getList?.filter(item => item.keyActiveFlag === true);
        const sortedApiKeyList = sortApiKeyList(activeApiKeyList, 'desc', 'createdOnTimestamp');
        const filteredData = filterAndSortData(sortedApiKeyList, apiKeySearchValue, sortOrder);
        setTotalPages(Math.ceil(filteredData?.length / itemsPerPage));
        setCurrentPage(1);
        setUserApiKeyList(filteredData);
        setOrginalUserApiKeyList(sortedApiKeyList);
      } else {
        setShowErrorMessage(true);
      }
    } catch (error) {
      console.error('Error creating API key:', error);
    } finally {
      props.getLoader(false);
    }
  };

  const handleEditApiKey = async (data) => {
    props.getLoader(true);
    try {
      const res = await updateApiKey(data);
      if (res?.status) {
        document.getElementById('apiKeyPopupCloseButton').click();
        setShowCreatePopup(false);
        const getList = await getApiKeyList();
        const activeApiKeyList = getList?.filter(item => item.keyActiveFlag === true);
        const sortedApiKeyList = sortApiKeyList(activeApiKeyList, 'desc', 'createdOnTimestamp');
        const filteredData = filterAndSortData(sortedApiKeyList, apiKeySearchValue, sortOrder);
        setTotalPages(Math.ceil(filteredData?.length / itemsPerPage));
        setCurrentPage(1);
        setUserApiKeyList(filteredData);
        setOrginalUserApiKeyList(sortedApiKeyList);
      } else {
        setShowErrorMessage(true);
      }
    } catch (error) {
      console.error('Error editing API key:', error);
    } finally {
      props.getLoader(false);
    }
  };

  const handleapiKeyAction = (apiKeyItem, action) => {
    if (action === 'deactivate') {
      setSelectedApiKey(apiKeyItem);
      setSelectedAction(action);
      setConfirmationPopUp(true);
    } else {
      setSelectedAction(action);
      setEditPopUpData(apiKeyItem);
      setShowCreatePopup(true);
    }
  };

  const handleDeactivateApiKey = async (apiKey) => {
    props.getLoader(true);
    try {
      const res = await deactivateApiKey(apiKey);
      if (res?.status) {
        document.getElementById('apiKeyCloseConfirmationModal').click();
        const getList = await getApiKeyList();
        const activeApiKeyList = getList?.filter(item => item.keyActiveFlag === true);
        const sortedApiKeyList = sortApiKeyList(activeApiKeyList, 'desc', 'createdOnTimestamp');
        const filteredData = filterAndSortData(sortedApiKeyList, apiKeySearchValue, sortOrder);
        setTotalPages(Math.ceil(filteredData?.length / itemsPerPage));
        setCurrentPage(1);
        setUserApiKeyList(filteredData);
        setOrginalUserApiKeyList(sortedApiKeyList);
      }
    } catch (error) {
      console.error('Error deactivating API key:', error);
    } finally {
      props.getLoader(false);
    }
  };

  const filterAndSortData = (data, searchValue, sortOrder) => {
    let filteredData = data;
    if (searchValue !== '') {
      filteredData = data?.filter(item => {
        const lowerCaseSearchValue = searchValue.toLowerCase();
        return (
          item?.apiKeyName?.toLowerCase().includes(lowerCaseSearchValue) ||
          item?.emailAddress?.toLowerCase().includes(lowerCaseSearchValue) ||
          item?.createdBy?.toLowerCase().includes(lowerCaseSearchValue)
        );
      });
    }
    if (sortOrder.sortParameter !== '') {
      filteredData = filteredData.sort((a, b) => {
        if (a[sortOrder.sortParameter] === null) return 1;
        if (b[sortOrder.sortParameter] === null) return -1;
        return sortOrder.sortingOrder === 'asc'
          ? a[sortOrder.sortParameter]?.localeCompare(b[sortOrder.sortParameter])
          : b[sortOrder.sortParameter]?.localeCompare(a[sortOrder.sortParameter]);
      });
    }
    return filteredData;
  };

  const handleSort = (parameter) => {
    const newSortOrder = sortOrder?.sortingOrder === 'asc' ? 'desc' : 'asc';
  setSortOrder({ sortingOrder: newSortOrder, sortParameter: parameter });

  let userApiKey = [...userApiKeyList]?.sort((a, b) => {
    if (a[parameter] === null) return 1;
    if (b[parameter] === null) return -1;
    if (newSortOrder === 'asc') {
      return a[parameter]?.localeCompare(b[parameter]);
    } else {
      return b[parameter]?.localeCompare(a[parameter]);
    }
  });

  let orginalUserList = [...orginalUserApiKeyList]?.sort((a, b) => {
    if (a[parameter] === null) return 1;
    if (b[parameter] === null) return -1;
    if (newSortOrder === 'asc') {
      return a[parameter].localeCompare(b[parameter]);
    } else {
      return b[parameter].localeCompare(a[parameter]);
    }
  });

  setUserApiKeyList(userApiKey);
  setOrginalUserApiKeyList(orginalUserList);
  setShowSortModal(false);
  };

  useEffect(() => {
    const filteredData = filterAndSortData(orginalUserApiKeyList, apiKeySearchValue, sortOrder);
    setUserApiKeyList(filteredData);
    setTotalPages(Math.ceil(filteredData?.length / itemsPerPage));
    setCurrentPage(1);
  }, [apiKeySearchValue]);

  useEffect(() => {
    let newTotalPages = Math.ceil(userApiKeyList.length / itemsPerPage);
    setTotalPages(newTotalPages);
    if(currentPage > newTotalPages){
      setCurrentPage(1);
    }
  }, [itemsPerPage]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  return (
    <div>
      <div className="container-fluid">
        <div className="ph-header-main__title row px-md-4 px-lg-3">
          <div className="col">
            <h1 className="p-title">API Keys</h1>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="ph-header-main__breadcrumbs row px-md-4 px-lg-3 py-2">
          <div className="col">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item" aria-current="page">
                  <a href={'/'}>{translationUtils.getTranslation(791, 'Home')}</a>
                </li>
                <li className="breadcrumb-item" aria-current="page">Api Keys</li>
              </ol>
            </nav>
          </div>
        </div>
      </div>
      {message && (
        <PopUpComponent status={status} message={message} />
      )}
      <div className="container px-5">
        <div className="row justify-content-center">
          <div className="col-12">
            <div className="header-details pt-4 pb-4">
              {`${translationUtils.getTranslation(198, 'All')} Api Keys`}
              <button
                style={{ float: 'right' }}
                type="button"
                className="button primary-button round-button medium-button ml-1"
                onClick={() => { setSelectedAction('create'); setShowCreatePopup(true); }}
              >
                {`${translationUtils.getTranslation(1350, 'Create')} Api Key`}
              </button>
              {showCreatePopup && (
                <CreateApiKey
                  handleCreateApiKey={handleCreateApiKey}
                  subKeyList={subKeyList}
                  showCreatePopup={(e) =>{setShowCreatePopup(e);setShowErrorMessage(e)}}
                  showErrorMessage={showErrorMessage}
                  selectedAction={selectedAction}
                  editPopUpData={editPopUpData}
                  handleEditApiKey={handleEditApiKey}
                />
              )}
              {confirmationPopUp && (
                <ConfirmationPopUp
                  action={selectedAction}
                  apiKey={selectedApiKey}
                  handleDeactivateApiKey={handleDeactivateApiKey}
                  showConfirmationPopUp={setConfirmationPopUp}
                />
              )}
            </div>
            <div className="apiKeySearch  ">
              <label htmlFor="apiKeySearch" className="mt-0">Quick Filter By Key Name & Email</label>
              <input
                type="search"
                id="apiKeySearchInput"
                className="form-control"
                placeholder=" Search"
                value={apiKeySearchValue}
                onChange={(e) => setApiKeySearchValue(e.target.value)}
              />
             
            </div>

            
            <div className="apiKey-table-wrapper">
              <table id="apiKeyTable" className="table">
                <thead>
                  <tr>
                    <th
                      scope="col"
                      colSpan={1}
                      className="text-center text-align-center"
                      onClick={() => { userApiKeyList.length > 0 && handleSort('apiKeyName'); }}
                      style={{ width: "auto" }}
                    >
                      <span>{`Api Key ${translationUtils.getTranslation(355, 'Name')}`}</span>
                      {userApiKeyList.length > 0 && <i className="fa fa-sort th-margin"></i>}
                    </th>
                    <th
                      scope="col"
                      colSpan={1}
                      className="text-center text-align-center"
                      onClick={() => { userApiKeyList.length > 0 && handleSort('emailAddress'); }}
                    >
                      Created To
                      {userApiKeyList.length > 0 && <i className="fa fa-sort th-margin"></i>}
                    </th>
                    <th
                      scope="col"
                      colSpan={1}
                      className="text-center text-align-center"
                      onClick={() => { userApiKeyList.length > 0 && handleSort('createdBy'); }}
                    >
                      Created By
                      {userApiKeyList.length > 0 && <i className="fa fa-sort th-margin"></i>}
                    </th>
                    <th
                      scope="col"
                      colSpan={1}
                      className="text-center text-align-center"
                      onClick={() => { userApiKeyList.length > 0 && handleSort('lastAccessed'); }}
                    >
                      Last Accessed
                      {userApiKeyList.length > 0 && <i className="fa fa-sort th-margin"></i>}
                    </th>
                    <th scope="col" colSpan={1} className="text-center text-align-center">
                      <span>{`${translationUtils.getTranslation(1064, 'Actions')}`}</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {userApiKeyList?.length > 0 ? (
                    userApiKeyList?.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage).map((apiKeyItem, index) => (
                      <React.Fragment key={index}>
                        <ApiListItem
                        key={index}
                          apiKeyItem={apiKeyItem}
                          index={index}
                          handleapiKeyAction={handleapiKeyAction}
                        />
                      </React.Fragment>
                    ))
                  ) : (
                    <>
                      <tr id="apiKeyEmptyRow"><td colSpan="5"></td></tr>
                      <tr id="apiKeyNoRecordsFound">
                        <td colSpan="12" className="text-center">{`${translationUtils.getTranslation(2090, 'No records found')}`}</td>
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
            <div className="apiKeyBottomPagination">
              <nav aria-label="Page navigation" className="api-key-pagination">
                <ul className="pagination">
                  <ApiKeyPagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                  />
                </ul>
              </nav>
              <div className="results-per-page">
                <p className="mt-2"><b>{translationUtils.getTranslation(621, 'Results Per Page')}:</b></p>
                <select id="itemsPerPage" value={itemsPerPage} onChange={(e) => setItemsPerPage(e.target.value)}>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={50}>50</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApiKeyMain;